import { ConsumptionModeType } from '@innovorder/order_detail';
import { useEffect, useState } from 'react';
import { getConsumptionModeList, updateConsumptionModeList } from 'redux/consumptionModeList';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { useConsumptionModeListMutation } from 'services/consumptionMode/consumptionMode.endpoints';
import { ChannelId } from 'services/order/order.type';

export const HANDLED_CONSUMPTION_MODES = [
    ConsumptionModeType.MODE_SIT_IN,
    ConsumptionModeType.MODE_TAKE_AWAY,
];

export const useGetConsumptionModes = (restaurantIds?: number[]) => {
    const dispatch = useAppDispatch();
    const fetchedConsumptionModeList = useAppSelector(getConsumptionModeList);
    const [getConsumptionModes] = useConsumptionModeListMutation();
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        if (!getConsumptionModes) return;
        if (!restaurantIds) return;

        if (isFetching) return;
        setIsFetching(true);

        const fetchedConsumptionModeRestaurantIds = fetchedConsumptionModeList
            ? Object.keys(fetchedConsumptionModeList)
            : [];

        const fetchRestaurantsConsumptionModes = async (ids: number[]) => {
            await Promise.all(
                ids.map(async (id) => {
                    const result = await getConsumptionModes({
                        channelId: ChannelId.WEB,
                        restaurantId: id,
                    });

                    if ('data' in result) {
                        const { data: consumptionModeList } = result;
                        dispatch(updateConsumptionModeList({ [id]: consumptionModeList }));
                    }
                }),
            );
            setIsFetching(false);
        };

        const consumptionModeRestaurantIdsToFetch = restaurantIds.filter(
            (id) => !fetchedConsumptionModeRestaurantIds.includes(String(id)),
        );

        fetchRestaurantsConsumptionModes(consumptionModeRestaurantIdsToFetch);
    }, [isFetching, restaurantIds, dispatch, fetchedConsumptionModeList, getConsumptionModes]);

    return { isFetching };
};
