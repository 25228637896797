import { ENV } from 'utils/env';
import { rest } from 'msw';
import {
    discountRecapFixtureFactory,
    discountStrategyFixtureFactory,
} from '@innovorder/order_detail/dist/fixture_factory/order_recap.fixture_factory';
import { ConsumptionModeType, DISCOUNT_TYPE } from '@innovorder/order_detail';
import { DateTime } from 'luxon';
import { OrderMainStatus } from 'services/order/order.type';
import { weborderingConfigurationFixtureFactory } from '../factories/weborderingConfiguration';
import { menuFixtureFactory } from '../factories/menu';
import {
    omnicanalOrderFixtureFactory,
    orderCreatedFixtureFactory,
    orderVerifiedFixtureFactory,
} from '../factories/order';
import {
    multiOrderCartErrorPayload,
    mutiOrderErrorWith3Baskets,
    mutiOrderErrorWithMultiBasket,
    orderMock,
    singleOrderCartErrorPayload,
    singleSimpleOrderCartErrorPayload,
} from '../factories/order/mock';
import { consumptionModeFixtureFactory } from '../factories/consumptionMode';
import { weborderingRestaurantFixtureFactory } from '../factories/weborderingRestaurant';
import { weborderingRestaurantFamilyFixtureFactory } from '../factories/weborderingRestaurantFamily';

export const MENUID_THROWING_ERROR_ON_PRODUCT_1 = 4001;
export const MENUID_THROWING_ERROR_ON_PRODUCT_2 = 4002;
export const RESTAURANTID_ERROR_PREVIEW_CREATE = 5;
export const RESTAURANTID_ERROR_CREATE = 2;
export const RESTAURANTID_ERROR_STRIPE_DECLINED = 6;

export const RESTAURANTID_NO_CONSUMPTION_MODE = 10;
export const RESTAURANTID_TWO_CONSUMPTION_MODES = 11;

export const handlers = [
    rest.get(`${ENV.API_URL}/brands/webordering-configuration/:brandHash`, (req, res, ctx) => {
        if (
            !req.params.brandHash ||
            ['error', 'no-brand'].includes(req.params.brandHash as string)
        ) {
            return res(ctx.status(400), ctx.json({ message: `Unknown brand` }));
        }
        return res(
            ctx.json({
                data: weborderingConfigurationFixtureFactory(),
            }),
        );
    }),

    rest.get(`${ENV.API_URL}/brand/:brandHash/restaurants`, (req, res, ctx) => {
        return res(
            ctx.json({
                data: [
                    weborderingRestaurantFixtureFactory({
                        brandRestaurantFamilies: [{ brandRestaurantFamilyId: 1 }],
                    }),
                ],
            }),
        );
    }),

    rest.get(`${ENV.API_URL}/brand/:brandHash/restaurants_families`, (req, res, ctx) => {
        return res(
            ctx.json({
                data: [weborderingRestaurantFamilyFixtureFactory({ brandRestaurantFamilyId: 1 })],
            }),
        );
    }),

    rest.get(`${ENV.API_URL}/menus/:menuId/hash_map`, (req, res, ctx) => {
        if (!req.params.menuId || ['no-menu'].includes(req.params.menuId as string)) {
            return res(ctx.status(400), ctx.json({ message: `Unknown menu` }));
        }
        return res(ctx.status(200), ctx.json({ data: menuFixtureFactory() }));
    }),

    rest.get(`${ENV.API_URL}/consumption_modes`, async (req, res, ctx) => {
        const requestedUrl = req.url.toString();
        const isEmpty = requestedUrl.includes(`restaurant_id=${RESTAURANTID_NO_CONSUMPTION_MODE}`);
        const hasTwoModes = requestedUrl.includes(
            `restaurant_id=${RESTAURANTID_TWO_CONSUMPTION_MODES}`,
        );

        if (isEmpty) return res(ctx.status(200), ctx.json({ data: [] }));
        if (hasTwoModes)
            return res(
                ctx.status(200),
                ctx.json({
                    data: [
                        consumptionModeFixtureFactory(),
                        consumptionModeFixtureFactory({ type: ConsumptionModeType.MODE_TAKE_AWAY }),
                    ],
                }),
            );
        return res(ctx.status(200), ctx.json({ data: [consumptionModeFixtureFactory()] }));
    }),

    rest.post(`${ENV.API_URL}/orders/preview`, async (req, res, ctx) => {
        const body = await req.json();

        if (body.restaurantId === RESTAURANTID_ERROR_PREVIEW_CREATE) {
            return res(ctx.status(400), ctx.json(singleSimpleOrderCartErrorPayload));
        }

        if (
            body.menuId === MENUID_THROWING_ERROR_ON_PRODUCT_1 &&
            body.cart.map(({ productId }: { productId: number }) => productId).includes(1)
        ) {
            return res(ctx.status(400), ctx.json(singleOrderCartErrorPayload));
        }

        if (body.promocode && body.promocode.includes('error')) {
            return res(
                ctx.status(400),
                ctx.json({
                    status: 400,
                    code: 'invalid_order_payload',
                    message: 'invalid order payload',
                    extraData: {
                        errors: [
                            {
                                status: 400,
                                message: 'wrong promocode',
                                code: 'promocode_not_found',
                                extraData: {},
                            },
                        ],
                        restaurantId: body.restaurantId,
                    },
                }),
            );
        }

        return res(
            ctx.status(200),
            ctx.json({
                data: orderVerifiedFixtureFactory(body.restaurantId === 1 ? body : orderMock, [
                    discountRecapFixtureFactory({
                        strategy: discountStrategyFixtureFactory({
                            type: DISCOUNT_TYPE.PROMOCODE,
                        }),
                    }),
                ]),
            }),
        );
    }),

    rest.post(`${ENV.API_URL}/multi-orders/preview`, async (req, res, ctx) => {
        const body = await req.json();

        const menus = body.restaurantCarts.map(({ menuId }: { menuId: number }) => menuId);
        const carts = body.restaurantCarts.map(({ cart }: { cart: unknown }) => cart).flat();
        const restaurants = body.restaurantCarts.map(
            ({ restaurantId }: { restaurantId: number }) => restaurantId,
        );

        if (restaurants.includes(RESTAURANTID_ERROR_PREVIEW_CREATE)) {
            return res(ctx.status(400), ctx.json(multiOrderCartErrorPayload));
        }

        if (
            menus.includes(MENUID_THROWING_ERROR_ON_PRODUCT_1) &&
            carts.map(({ productId }: { productId: number }) => productId).includes(1)
        ) {
            return res(ctx.status(400), ctx.json(multiOrderCartErrorPayload));
        }

        if (
            menus.includes(MENUID_THROWING_ERROR_ON_PRODUCT_2) &&
            carts.map(({ productId }: { productId: number }) => productId).includes(3)
        ) {
            return res(ctx.status(400), ctx.json(mutiOrderErrorWith3Baskets));
        }

        if (
            menus.includes(MENUID_THROWING_ERROR_ON_PRODUCT_2) &&
            carts.map(({ productId }: { productId: number }) => productId).includes(2)
        ) {
            return res(ctx.status(400), ctx.json(mutiOrderErrorWithMultiBasket));
        }

        return res(
            ctx.status(200),
            ctx.json({
                data: {
                    ...body,
                    verifiedOrders: body?.restaurantCarts ?? [],
                },
            }),
        );
    }),

    rest.post(`${ENV.API_URL}/orders`, async (req, res, ctx) => {
        const body = await req.json();

        if (body.restaurantId === RESTAURANTID_ERROR_CREATE) {
            return res(ctx.status(400), ctx.json({ error: `error` }));
        }

        if (body.restaurantId === 3) {
            return res(
                ctx.status(200),
                ctx.json({
                    data: orderCreatedFixtureFactory({
                        ...body,
                        requiredAction: {
                            orderUuid: 'abc-123',
                            intentId: '1',
                            payment_intent_client_secret: '1',
                            requires_action: true,
                        },
                    }),
                }),
            );
        }

        if (body.restaurantId === 4 || body.restaurantId === 5) {
            return res(ctx.status(400), ctx.json(singleOrderCartErrorPayload));
        }

        if (body.restaurantId === RESTAURANTID_ERROR_STRIPE_DECLINED) {
            return res(
                ctx.status(403),
                ctx.json({
                    status: 403,
                    code: 'stripe_card_declined',
                }),
            );
        }

        return res(
            ctx.status(200),
            ctx.json({
                data: {
                    ...orderCreatedFixtureFactory(body.restaurantId === 1 ? body : orderMock),
                    mainStatus: OrderMainStatus.ORDER_PLACED,
                },
            }),
        );
    }),

    rest.post(`${ENV.API_URL}/multi-orders`, async (req, res, ctx) => {
        const body = await req.json();

        const restaurantsId = body.restaurantCarts.map(
            ({ restaurantId }: { restaurantId: number }) => restaurantId,
        );

        if (restaurantsId.includes(2)) {
            return res(ctx.status(400), ctx.json({ error: `error` }));
        }

        if (restaurantsId.includes(3)) {
            return res(
                ctx.status(200),
                ctx.json({
                    data: orderCreatedFixtureFactory({
                        ...body,
                        multiOrderId: 'abc-123',
                        multiCreatedOrders: [{ orderUuid: 'abc-123', restaurantId: 3 }],
                        requiredAction: {
                            intentId: '1',
                            payment_intent_client_secret: '1',
                            requires_action: true,
                        },
                        mainStatus: OrderMainStatus.ORDER_PLACED,
                    }),
                }),
            );
        }

        if (restaurantsId.includes(4) || restaurantsId.includes(5)) {
            return res(ctx.status(400), ctx.json(multiOrderCartErrorPayload));
        }

        return res(
            ctx.status(200),
            ctx.json({
                data: {
                    ...body,
                    multiOrderId: 'abc-123',
                    mainStatus: OrderMainStatus.ORDER_PLACED,
                },
            }),
        );
    }),

    rest.post(`${ENV.API_URL}/orders/confirm`, async (req, res, ctx) => {
        const body = await req.json();

        if (body.orderId === 2 || body.orderUuid === '22222222-2222-2222-2222-222222222222') {
            return res(ctx.status(400), ctx.json({ error: `error` }));
        }

        return res(
            ctx.status(200),
            ctx.json({
                data: orderCreatedFixtureFactory(body.restaurantId === 1 ? body : orderMock),
            }),
        );
    }),

    rest.post(`${ENV.API_URL}/multi-orders/confirm`, async (req, res, ctx) => {
        const body = await req.json();

        if (body.multiOrderId === 'def-456') {
            return res(ctx.status(400), ctx.json({ error: `error` }));
        }

        return res(
            ctx.status(200),
            ctx.json({
                data: [orderCreatedFixtureFactory(body.restaurantId === 1 ? body : orderMock)],
            }),
        );
    }),

    rest.get(`${ENV.API_URL}/v1/orders/uuid/:orderUuid`, async (req, res, ctx) => {
        if (req.params.orderUuid === 'abc-123') {
            return res(
                ctx.status(200),
                ctx.json({
                    data: {
                        orderUuid: req.params.orderUuid,
                        restaurantId: 1,
                        dailyOrderId: 41,
                        restaurant: {},
                        cartRawData: '{"enrichedCart": []}',
                    },
                }),
            );
        }
        return res(
            ctx.status(200),
            ctx.json({
                data: omnicanalOrderFixtureFactory({
                    discounts: [
                        {
                            name: 'NOEL',
                            value: 100,
                        },
                    ],
                }),
            }),
        );
    }),

    rest.get(`${ENV.API_URL}/multi-orders/:multiOrderUuid`, async (req, res, ctx) => {
        const { multiOrderUuid } = req.params;
        const isNewOrder = multiOrderUuid.includes('new-order');
        const isProcessed = multiOrderUuid.includes('order-processed');

        return res(
            ctx.status(200),
            ctx.json({
                data: {
                    multiOrderUuid,
                    createdAt: isNewOrder ? DateTime.now().toISO() : '2023-10-18T12:34:42.502Z',
                    orders: [
                        {
                            orderId: 10,
                            dailyOrderId: 41,
                            restaurantId: 1,
                            restaurant: { name: 'Big Fernand' },
                            cartRawData: '{"enrichedCart": []}',
                            mainStatus: isProcessed ? OrderMainStatus.ORDER_PROCESSED : undefined,
                        },
                    ],
                },
            }),
        );
    }),

    rest.post(`${ENV.API_URL}/orders/receipt`, async (req, res, ctx) => {
        const body = await req.json();

        if (body.email === 'error@message.com') {
            return res(ctx.status(400), ctx.json({ status: 400, message: `error_message` }));
        }

        return res(
            ctx.status(200),
            ctx.json({
                data: undefined,
            }),
        );
    }),

    rest.post(`${ENV.API_URL}/multi-orders/receipt`, async (req, res, ctx) => {
        const body = await req.json();

        if (body.email === 'error@message.com') {
            return res(ctx.status(400), ctx.json({ status: 400, message: `error_message` }));
        }

        return res(
            ctx.status(200),
            ctx.json({
                data: undefined,
            }),
        );
    }),

    rest.get('/*', (req) => {
        return req.passthrough();
    }),

    rest.post('https://*.ingest.sentry.io/*', (req, res, ctx) => {
        return res(ctx.json(null));
    }),

    rest.post(`${ENV.ELASTIC_APM_SERVER_URL}/*`, (req, res, ctx) => {
        return res(ctx.json(null));
    }),
];
