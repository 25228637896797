"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderDetail = void 0;
var types_1 = require("../adapters/pos-order-adapter/types");
var discount_types_1 = require("../types/discount.types");
var big_numbers_1 = require("../utils/big-numbers");
var apply_discounts_1 = require("./discounts/apply-discounts");
var compute_discount_tax_breakdown_1 = require("./discounts/compute-discount-tax-breakdown");
var legacy_global_discount_1 = require("./discounts/legacy-global-discount");
var sort_discounts_1 = require("./discounts/sort-discounts");
var filter_fees_1 = require("./fees/filter-fees");
var filter_null_admissions_1 = require("./fees/filter-null-admissions");
var map_order_detail_discount_to_discount_1 = require("./mappers/inputs/map-order-detail-discount-to-discount");
var map_order_detail_fee_to_fee_1 = require("./mappers/inputs/map-order-detail-fee-to-fee");
var map_order_detail_product_to_product_1 = require("./mappers/inputs/map-order-detail-product-to-product");
var map_discount_to_discount_recap_1 = require("./mappers/outputs/map-discount-to-discount-recap");
var map_fee_to_fee_recap_1 = require("./mappers/outputs/map-fee-to-fee-recap");
var map_product_to_product_recap_1 = require("./mappers/outputs/map-product-to-product-recap");
var map_urssaf_to_product_recap_1 = require("./mappers/outputs/map-urssaf-to-product-recap");
var urssaf_compensatory_product_1 = require("./mappers/urssaf-compensatory-product");
var add_parents_tags_to_child_products_1 = require("./products/add-parents-tags-to-child-products");
var compute_unit_price_1 = require("./products/compute-unit-price");
var rounding_1 = require("./roundings/rounding");
var compute_tax_rate_1 = require("./tax-rate/compute-tax-rate");
var compute_total_price_from_items_1 = require("./utils-computing/compute-total-price-from-items");
var OrderDetail = /** @class */ (function () {
    function OrderDetail(currency, orderDetailProducts, orderDetailFees, orderDetailDiscounts, urssafTargetAmount) {
        if (orderDetailProducts === void 0) { orderDetailProducts = []; }
        if (orderDetailFees === void 0) { orderDetailFees = []; }
        if (orderDetailDiscounts === void 0) { orderDetailDiscounts = []; }
        this.currency = currency;
        this.orderDetailProducts = orderDetailProducts;
        this.orderDetailFees = orderDetailFees;
        this.orderDetailDiscounts = orderDetailDiscounts;
        this.urssafTargetAmount = urssafTargetAmount;
        this.products = [];
        this.fees = [];
        this.discounts = [];
        this.urssaf = null;
        this.processOrder();
    }
    OrderDetail.prototype.processOrder = function () {
        this.generateAttributes();
        this.updateTotalPrices();
        this.computeDiscounts();
        this.roundPricesGradually();
        this.computeDiscountsTaxBreakdown();
        this.computeTurnoverAmounts();
    };
    Object.defineProperty(OrderDetail.prototype, "recap", {
        get: function () {
            var feesRecap = this.fees.map(function (fee) { return (0, map_fee_to_fee_recap_1.mapFeeToFeeRecap)(fee); });
            var discountsRecap = this.discounts
                .map(function (discount) { return (0, map_discount_to_discount_recap_1.mapDiscountToDiscountRecap)(discount); })
                .filter(function (discount) { return discount.amount > 0; });
            var productsRecap = __spreadArray([], this.products, true).map(function (product) { return (0, map_product_to_product_recap_1.mapProductToProductRecap)(product); });
            if (this.urssaf) {
                productsRecap.push((0, map_urssaf_to_product_recap_1.mapUrssafToProductRecap)(this.urssaf));
            }
            return {
                currency: this.currency,
                products: productsRecap,
                fees: feesRecap,
                discounts: discountsRecap,
                taxRatesBreakdown: this.computeTaxRates(),
                totalPrice: this.totalPriceRounded,
                totalLuncheonEligibleAmount: this.getLuncheonEligibleTotalAmount(),
                globalDiscount: (0, legacy_global_discount_1.getGlobalDiscount)(this.discounts.filter(function (dis) { return discount_types_1.RemiseTypes.includes(dis.type); })),
                urssafCompensatoryProduct: this.urssaf
                    ? { amount: this.urssaf.totalPriceRounded.totalInclTaxWithDiscount }
                    : undefined,
                entranceFeeAmount: this.totalAdmissionsPriceRounded.totalInclTax,
                grantAmount: this.grantAmount,
                totalDiscount: this.totalDiscount,
                turnover: this.turnoverWithTaxIncluded,
                turnoverWithTaxExcluded: this.turnoverWithTaxExcluded,
                turnoverTotalTax: this.turnoverWithTaxIncluded - this.turnoverWithTaxExcluded,
                offTurnover: this.offTurnover,
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(OrderDetail.prototype, "orderRecap", {
        get: function () {
            return this.recap;
        },
        enumerable: false,
        configurable: true
    });
    OrderDetail.prototype.generateAttributes = function () {
        this.products = this.orderDetailProducts
            .filter(function (_a) {
            var quantity = _a.quantity;
            return !!quantity;
        })
            .filter(function (product) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            return !product.additionalData || product.additionalData.orderItemType !== types_1.PosOrderItemType.COMPENSATION;
        })
            .map(function (product) { return (0, map_order_detail_product_to_product_1.mapOrderDetailProductToproduct)(product); });
        this.products = (0, add_parents_tags_to_child_products_1.addParentTagsToChildProducts)(this.products);
        this.fees = (0, filter_null_admissions_1.filterNullAdmissionsFromOrderDetailFee)(this.orderDetailFees).map(function (fee) {
            return (0, map_order_detail_fee_to_fee_1.mapOrderDetailFeeToFee)(fee);
        });
        var discounts = this.orderDetailDiscounts.map(function (discount) { return (0, map_order_detail_discount_to_discount_1.mapOrderDetailDiscountToDiscount)(discount); });
        this.discounts = (0, sort_discounts_1.sortDiscountsByPosition)(discounts);
    };
    OrderDetail.prototype.updateTotalPrices = function () {
        this.totalPrice = (0, compute_total_price_from_items_1.computeTotalPriceFromItems)(__spreadArray(__spreadArray([], this.products, true), this.fees, true));
        this.totalProductsPrice = (0, compute_total_price_from_items_1.computeTotalPriceFromItems)(this.products);
        this.totalAdmissionsPrice = (0, compute_total_price_from_items_1.computeTotalPriceFromItems)((0, filter_fees_1.filterTurnoverFees)(this.fees));
        this.totalRoyaltiesPrice = (0, compute_total_price_from_items_1.computeTotalPriceFromItems)((0, filter_fees_1.filterOffTurnoverFees)(this.fees));
    };
    OrderDetail.prototype.computeDiscounts = function () {
        var _a = (0, apply_discounts_1.applyDiscounts)(__spreadArray(__spreadArray([], this.products, true), this.fees, true), this.discounts), products = _a.products, fees = _a.fees, discounts = _a.discounts;
        this.products = products;
        this.fees = fees;
        this.discounts = discounts;
        this.updateTotalPrices();
    };
    OrderDetail.prototype.roundPricesGradually = function () {
        var urssafCompensatoryProductComputed = this.computeUrssafCompensatoryProduct();
        var itemsAndTotal = (0, rounding_1.roundItemsConsistently)([
            this.totalProductsPrice,
            this.totalAdmissionsPrice,
            this.totalRoyaltiesPrice,
            urssafCompensatoryProductComputed.totalPriceNotRounded,
        ]);
        var items = itemsAndTotal.items;
        var total = itemsAndTotal.total;
        this.totalPriceRounded = (0, rounding_1.addTaxAmounts)(total);
        this.totalProductsPriceRounded = (0, rounding_1.addTaxAmounts)(items[0]);
        this.totalAdmissionsPriceRounded = (0, rounding_1.addTaxAmounts)(items[1]);
        this.totalRoyaltiesPriceRounded = (0, rounding_1.addTaxAmounts)(items[2]);
        urssafCompensatoryProductComputed.totalPriceRounded = (0, rounding_1.addTaxAmounts)(items[3]);
        this.offTurnoverPriceRounded = (0, rounding_1.sumRoundedItemsByKey)([
            this.totalRoyaltiesPriceRounded,
            urssafCompensatoryProductComputed.totalPriceRounded,
        ]);
        // Compute product.totalPriceRounded
        (0, rounding_1.roundAndAddTaxAmounts)(this.products, this.totalProductsPriceRounded);
        // Compute admission.totalPriceRounded
        var admissions = (0, filter_fees_1.filterTurnoverFees)(this.fees);
        (0, rounding_1.roundAndAddTaxAmounts)(admissions, this.totalAdmissionsPriceRounded);
        // Compute royalty.totalPriceRounded
        var royalties = (0, filter_fees_1.filterOffTurnoverFees)(this.fees);
        (0, rounding_1.roundAndAddTaxAmounts)(royalties, this.totalRoyaltiesPriceRounded);
        // Compute product.unitPriceRounded
        (0, compute_unit_price_1.computeUnitPrice)(this.products);
    };
    OrderDetail.prototype.computeUrssafCompensatoryProduct = function () {
        var urssafCompensatoryProductComputed = (0, urssaf_compensatory_product_1.generateUrssafCompensatoryProduct)(this.totalPrice, this.products, this.totalAdmissionsPrice, this.totalRoyaltiesPrice, this.urssafTargetAmount);
        if (urssafCompensatoryProductComputed.totalPriceNotRounded.totalInclTax.comparedTo(big_numbers_1.big0) === 1) {
            this.urssaf = urssafCompensatoryProductComputed;
        }
        return urssafCompensatoryProductComputed;
    };
    OrderDetail.prototype.computeTaxRates = function () {
        return (0, compute_tax_rate_1.computeTaxRatesFromTotals)(this.products, this.fees);
    };
    OrderDetail.prototype.computeDiscountsTaxBreakdown = function () {
        this.totalDiscount = this.totalPriceRounded.totalInclTax - this.totalPriceRounded.totalInclTaxWithRemise;
        var totalRemiseExclTax = this.totalPriceRounded.totalExclTax - this.totalPriceRounded.totalExclTaxWithRemise;
        this.grantAmount =
            this.totalPriceRounded.totalInclTaxWithRemise - this.totalPriceRounded.totalInclTaxWithDiscount;
        var totalGrantExclTax = this.totalPriceRounded.totalExclTaxWithRemise - this.totalPriceRounded.totalExclTaxWithDiscount;
        // compute remise.taxRatesBreakdownRounded
        var remises = this.discounts.filter(function (dis) { return discount_types_1.RemiseTypes.includes(dis.type); });
        var remisesUpdated = (0, compute_discount_tax_breakdown_1.computeDiscountTaxBreakdown)(remises, this.totalDiscount, totalRemiseExclTax);
        // compute grant.taxRatesBreakdownRounded
        var grants = this.discounts.filter(function (dis) { return !discount_types_1.RemiseTypes.includes(dis.type); });
        var grantsUpdated = (0, compute_discount_tax_breakdown_1.computeDiscountTaxBreakdown)(grants, this.grantAmount, totalGrantExclTax);
        this.discounts = __spreadArray(__spreadArray([], remisesUpdated, true), grantsUpdated, true);
    };
    OrderDetail.prototype.computeTurnoverAmounts = function () {
        this.offTurnover = this.offTurnoverPriceRounded.totalInclTax;
        this.turnoverWithTaxIncluded =
            this.totalPriceRounded.totalInclTaxWithRemise - this.offTurnoverPriceRounded.totalInclTaxWithRemise;
        this.turnoverWithTaxExcluded =
            this.totalPriceRounded.totalExclTaxWithRemise - this.offTurnoverPriceRounded.totalExclTaxWithRemise;
    };
    OrderDetail.prototype.getLuncheonEligibleTotalAmount = function () {
        var productLuncheonEligibleTotalAmount = this.products
            .filter(function (product) { return product.isEligibleLuncheon; })
            .reduce(function (mySum, product) { return mySum + product.totalPriceRounded.totalInclTaxWithDiscount; }, 0);
        var entranceFeeAmount = this.totalAdmissionsPriceRounded.totalInclTax;
        return entranceFeeAmount + productLuncheonEligibleTotalAmount;
    };
    return OrderDetail;
}());
exports.OrderDetail = OrderDetail;
