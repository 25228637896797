import React from 'react';

import { HeaderTitle, Loader, Title, WithHeader } from 'components';
import { CartStepper } from 'components/CartStepper';
import { Container } from './BaseConfirmPayment.style';
import { useBaseConfirmPaymentVM } from './useBaseConfirmPaymentVM';

export type BaseConfirmPaymentProps = {
    children: React.ReactNode;
    isLoading?: boolean;
};

const BaseConfirmPayment: React.FunctionComponent<BaseConfirmPaymentProps> = ({
    children,
    isLoading,
}) => {
    const { isFetching, navigateBackToChoosePayment } = useBaseConfirmPaymentVM();

    return (
        <WithHeader
            isSticky={true}
            onClickBack={navigateBackToChoosePayment}
            disabledClickBack={isLoading}
            disabledClickLogo={isLoading}
        >
            <CartStepper />
            <Container>
                <HeaderTitle>
                    <Title text={'order_confirmation'} type={4} />
                </HeaderTitle>
                {isFetching ? <Loader withContainer /> : children}
            </Container>
        </WithHeader>
    );
};

export default BaseConfirmPayment;
