import { ChangeEvent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'redux/store';
import { getUserName, updateAppState } from 'redux/app';
import { useWorkflowStateMachine } from 'hooks/useWorkflowStateMachine';

export const useUserInformationVM = () => {
    const USER_NAME_LENGTH_MIN = 2;
    const dispatch = useDispatch();
    const userName = useAppSelector(getUserName);
    const { goNext } = useWorkflowStateMachine();

    const { t } = useTranslation();

    const handleUpdateUserName = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            dispatch(
                updateAppState({
                    userName: event.target.value,
                }),
            );
        },
        [dispatch],
    );

    return {
        buttonText: t('go_to_payment'),
        userName,
        handleUpdateUserName,
        goNext,
        USER_NAME_LENGTH_MIN,
    };
};
