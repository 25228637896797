"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.posOrderDiscountsAdapterV4 = exports.posOrderDiscountsAdapterV2 = exports.posOrderDiscountsAdapterV1 = void 0;
var bignumber_js_1 = require("bignumber.js");
var types_1 = require("../../../types");
var types_2 = require("../types");
var submodels_adapter_1 = require("./submodels.adapter");
var utils_1 = require("./utils");
var posOrderDiscountsAdapterV1 = function (_a, digitsRange) {
    var orderItems = _a.orderItems, discount = _a.discount, orderFlags = _a.orderFlags;
    var orderDetailDiscounts = new Map();
    var globalRatedDiscount = (0, submodels_adapter_1.globalRateDiscountAdapter)({ orderItems: orderItems, discount: discount });
    if (globalRatedDiscount) {
        orderDetailDiscounts.set('POS_DISCOUNT', globalRatedDiscount);
    }
    orderItems
        .filter(function (orderItem) { return orderItem.orderItemType === types_2.PosOrderItemType.GRANT; })
        .reduce(function (_orderItems, orderItem) { return [orderItem]; }, [])
        .forEach(function (orderItem) {
        if (!orderDetailDiscounts.has(orderItem.name)) {
            if (orderFlags.length) {
                orderDetailDiscounts.set(orderItem.name, (0, submodels_adapter_1.grantFromOrderFlagsAdapter)(orderFlags, orderItem));
            }
            else {
                orderDetailDiscounts.set(orderItem.name, (0, submodels_adapter_1.grantFromOrderItemAdapter)(orderItem, digitsRange));
            }
        }
    });
    orderItems
        .filter(function (orderItem) { return orderItem.orderItemType === types_2.PosOrderItemType.LOYALTY; })
        .forEach(function (orderItem) {
        if (orderDetailDiscounts.has(types_2.PosOrderItemType.LOYALTY)) {
            var orderDetailDiscount = orderDetailDiscounts.get(types_2.PosOrderItemType.LOYALTY);
            var value = new bignumber_js_1.BigNumber(orderItem.originalUnitPrice)
                .negated()
                .shiftedBy(digitsRange)
                .plus(orderDetailDiscount.strategy.value)
                .toNumber();
            orderDetailDiscounts.set(types_2.PosOrderItemType.LOYALTY, __assign(__assign({}, orderDetailDiscount), { strategy: __assign(__assign({}, orderDetailDiscount.strategy), { value: value }) }));
        }
        else {
            orderDetailDiscounts.set(types_2.PosOrderItemType.LOYALTY, (0, submodels_adapter_1.loyaltyFromOrderFlagsAdapter)(orderFlags, orderItem));
        }
    });
    orderItems
        .filter(function (orderItem) { return orderItem.orderItemType === types_2.PosOrderItemType.PRODUCT || !orderItem.orderItemType; })
        .forEach(function (orderItem, index) {
        var _a = utils_1.promoCodeRegexp.exec(orderItem.ticketName) || [false, null], match = _a[0], label = _a[1];
        if (match) {
            var stringLabel = label.toString();
            if (orderDetailDiscounts.has(stringLabel)) {
                var orderDetailDiscount = orderDetailDiscounts.get(stringLabel);
                var value = new bignumber_js_1.BigNumber(orderItem.originalUnitPrice)
                    .absoluteValue()
                    .shiftedBy(digitsRange)
                    .plus(orderDetailDiscount.strategy.value)
                    .toNumber();
                orderDetailDiscounts.set(stringLabel, __assign(__assign({}, orderDetailDiscount), { strategy: __assign(__assign({}, orderDetailDiscount.strategy), { value: value }) }));
            }
            else {
                orderDetailDiscounts.set(stringLabel, (0, submodels_adapter_1.promocodeFromRegexpAdapter)(stringLabel, orderItem));
            }
        }
        else if ((new bignumber_js_1.BigNumber(orderItem.itemDiscount).lte(new bignumber_js_1.BigNumber(orderItem.originalUnitPrice).multipliedBy(orderItem.quantity)) &&
            new bignumber_js_1.BigNumber(orderItem.itemDiscount).gt(0)) ||
            orderItem.gift) {
            var children = orderItem.menu ? (0, utils_1.findFormulaChildren)(orderItem.sourceId, orderItems) : [];
            var manualDiscount = (0, submodels_adapter_1.manualDiscountAdapter)(orderItem, children, digitsRange, index);
            if (manualDiscount) {
                orderDetailDiscounts.set("MANUAL_DISCOUNT_".concat(index), manualDiscount);
            }
        }
    });
    return Array.from(orderDetailDiscounts.values());
};
exports.posOrderDiscountsAdapterV1 = posOrderDiscountsAdapterV1;
var posOrderDiscountsAdapterV2 = function (_a, digitsRange) {
    var orderItems = _a.orderItems, pricingRule = _a.pricingRule, discount = _a.discount, grants = _a.grants, loyalty = _a.loyalty;
    grants = grants || [];
    var orderDetailDiscounts = new Map();
    var globalRatedDiscount = (0, submodels_adapter_1.globalRateDiscountAdapter)({ orderItems: orderItems, discount: discount });
    if (globalRatedDiscount) {
        orderDetailDiscounts.set('POS_DISCOUNT', globalRatedDiscount);
    }
    var applyMethod = types_1.DiscountApplyMethod.ABSOLUTE_CART;
    var scope = {
        type: new Set([types_1.DISCOUNTABLE_TYPE.PRODUCT]),
        filter: null,
    };
    if (pricingRule) {
        scope.type = new Set(pricingRule.applyScope);
        applyMethod =
            scope.type.has(types_1.DISCOUNTABLE_TYPE.PRODUCT) && scope.type.has(types_1.DISCOUNTABLE_TYPE.FEE)
                ? types_1.DiscountApplyMethod.ABSOLUTE_GLOBAL
                : types_1.DiscountApplyMethod.ABSOLUTE_CART;
    }
    grants.forEach(function (grant) {
        orderDetailDiscounts.set(grant.code, {
            label: grant.code,
            id: grant.grantId,
            strategy: {
                applyMethod: applyMethod,
                type: types_1.DISCOUNT_TYPE.GRANT,
                value: grant.amount,
                scope: scope,
            },
            additionalData: grant,
        });
    });
    if (loyalty) {
        orderDetailDiscounts.set(types_2.PosOrderItemType.LOYALTY, {
            label: loyalty.cardId ? loyalty.cardId.toString() : null,
            id: loyalty.cardNumber || null,
            strategy: {
                applyMethod: types_1.DiscountApplyMethod.ABSOLUTE_GLOBAL,
                type: types_1.DISCOUNT_TYPE.LOYALTY,
                value: new bignumber_js_1.BigNumber(loyalty.amount).shiftedBy(digitsRange).toNumber(),
                scope: {
                    type: new Set([types_1.DISCOUNTABLE_TYPE.PRODUCT, types_1.DISCOUNTABLE_TYPE.FEE]),
                    filter: null,
                },
            },
            additionalData: loyalty,
        });
    }
    orderItems
        .filter(function (orderItem) { return orderItem.orderItemType === types_2.PosOrderItemType.PRODUCT; })
        .forEach(function (orderItem, index) {
        var _a = utils_1.promoCodeRegexp.exec(orderItem.ticketName) || [false, null], match = _a[0], label = _a[1];
        if (match) {
            var stringLabel = label.toString();
            if (orderDetailDiscounts.has(stringLabel)) {
                var orderDetailDiscount = orderDetailDiscounts.get(stringLabel);
                var value = new bignumber_js_1.BigNumber(orderItem.originalUnitPrice)
                    .absoluteValue()
                    .shiftedBy(digitsRange)
                    .plus(orderDetailDiscount.strategy.value)
                    .toNumber();
                orderDetailDiscounts.set(stringLabel, __assign(__assign({}, orderDetailDiscount), { strategy: __assign(__assign({}, orderDetailDiscount.strategy), { value: value }) }));
            }
            else {
                orderDetailDiscounts.set(stringLabel, (0, submodels_adapter_1.promocodeFromRegexpAdapter)(stringLabel, orderItem));
            }
        }
        else if (new bignumber_js_1.BigNumber(orderItem.itemDiscount).gt(0)) {
            var children = orderItem.menu ? (0, utils_1.findFormulaChildren)(orderItem.sourceId, orderItems) : [];
            var manualDiscount = (0, submodels_adapter_1.manualDiscountAdapter)(orderItem, children, digitsRange, index);
            if (manualDiscount) {
                orderDetailDiscounts.set("MANUAL_DISCOUNT_".concat(index), manualDiscount);
            }
        }
    });
    return Array.from(orderDetailDiscounts.values());
};
exports.posOrderDiscountsAdapterV2 = posOrderDiscountsAdapterV2;
var posOrderDiscountsAdapterV4 = function (_a, digitsRange) {
    var orderItems = _a.orderItems, pricingRule = _a.pricingRule, discount = _a.discount, grants = _a.grants, loyalty = _a.loyalty, totalPriceDiscountedWithTaxIncluded = _a.totalPriceDiscountedWithTaxIncluded, totalPriceWithTaxIncluded = _a.totalPriceWithTaxIncluded;
    grants = grants || [];
    var orderDetailDiscounts = new Map();
    var globalRatedDiscount = (0, submodels_adapter_1.globalRateDiscountAdapter)({ orderItems: orderItems, discount: discount });
    var globalAbsoluteDiscount = (0, submodels_adapter_1.globalAbsoluteDiscountAdapter)({
        orderItems: orderItems,
        discount: discount,
        totalPriceDiscountedWithTaxIncluded: totalPriceDiscountedWithTaxIncluded,
        totalPriceWithTaxIncluded: totalPriceWithTaxIncluded,
    });
    if (globalRatedDiscount) {
        orderDetailDiscounts.set('POS_DISCOUNT', globalRatedDiscount);
    }
    else if (globalAbsoluteDiscount) {
        orderDetailDiscounts.set('POS_DISCOUNT', globalAbsoluteDiscount);
    }
    var applyMethod = types_1.DiscountApplyMethod.ABSOLUTE_CART;
    var scope = {
        type: new Set([types_1.DISCOUNTABLE_TYPE.PRODUCT]),
        filter: null,
    };
    if (pricingRule) {
        scope.type = new Set(pricingRule.applyScope);
        applyMethod =
            scope.type.has(types_1.DISCOUNTABLE_TYPE.PRODUCT) && scope.type.has(types_1.DISCOUNTABLE_TYPE.FEE)
                ? types_1.DiscountApplyMethod.GRANT_ABSOLUTE_GLOBAL
                : types_1.DiscountApplyMethod.ABSOLUTE_CART;
    }
    var isGrantUsed = getIsGrantUsed(orderItems);
    grants.filter(isGrantUsed).forEach(function (grant) {
        orderDetailDiscounts.set(grant.code, {
            label: grant.code,
            id: grant.grantId,
            strategy: {
                applyMethod: grant.type === types_2.PosGrantType.RATE ? types_1.DiscountApplyMethod.RATE : applyMethod,
                type: types_1.DISCOUNT_TYPE.GRANT,
                value: grant.amount,
                scope: grant.tag
                    ? __assign(__assign({}, scope), { filter: {
                            type: types_1.DiscountFilterType.TAGS,
                            value: new Set([grant.tag.toLocaleLowerCase()]),
                        } }) : scope,
            },
            additionalData: grant,
        });
    });
    if (loyalty) {
        orderDetailDiscounts.set(types_2.PosOrderItemType.LOYALTY, {
            label: loyalty.cardId ? loyalty.cardId.toString() : null,
            id: loyalty.cardNumber,
            strategy: {
                applyMethod: types_1.DiscountApplyMethod.ABSOLUTE_GLOBAL,
                type: types_1.DISCOUNT_TYPE.LOYALTY,
                value: new bignumber_js_1.BigNumber(loyalty.amount).shiftedBy(digitsRange).toNumber(),
                scope: {
                    type: new Set([types_1.DISCOUNTABLE_TYPE.PRODUCT, types_1.DISCOUNTABLE_TYPE.FEE]),
                    filter: null,
                },
            },
            additionalData: loyalty,
        });
    }
    orderItems
        .filter(function (orderItem) { return orderItem.orderItemType === types_2.PosOrderItemType.PRODUCT; })
        .forEach(function (orderItem, index) {
        var _a = utils_1.promoCodeRegexp.exec(orderItem.ticketName) || [false, null], match = _a[0], label = _a[1];
        if (match) {
            var stringLabel = label.toString();
            if (orderDetailDiscounts.has(stringLabel)) {
                var orderDetailDiscount = orderDetailDiscounts.get(stringLabel);
                var value = new bignumber_js_1.BigNumber(orderItem.originalUnitPrice)
                    .absoluteValue()
                    .shiftedBy(digitsRange)
                    .plus(orderDetailDiscount.strategy.value)
                    .toNumber();
                orderDetailDiscounts.set(stringLabel, __assign(__assign({}, orderDetailDiscount), { strategy: __assign(__assign({}, orderDetailDiscount.strategy), { value: value }) }));
            }
            else {
                orderDetailDiscounts.set(stringLabel, (0, submodels_adapter_1.promocodeFromRegexpAdapter)(stringLabel, orderItem));
            }
        }
        else if (new bignumber_js_1.BigNumber(orderItem.itemDiscount).gt(0)) {
            var children = orderItem.menu ? (0, utils_1.findFormulaChildren)(orderItem.sourceId, orderItems) : [];
            var manualDiscount = (0, submodels_adapter_1.manualDiscountAdapter)(orderItem, children, digitsRange, index);
            if (manualDiscount) {
                orderDetailDiscounts.set("MANUAL_DISCOUNT_".concat(index), manualDiscount);
            }
        }
    });
    return Array.from(orderDetailDiscounts.values());
};
exports.posOrderDiscountsAdapterV4 = posOrderDiscountsAdapterV4;
function getIsGrantUsed(items) {
    var grantItems = items.filter(function (_a) {
        var orderItemType = _a.orderItemType;
        return orderItemType === types_2.PosOrderItemType.GRANT;
    });
    return function (_a) {
        var usedAmount = _a.usedAmount, labelTicket = _a.labelTicket;
        var grantHasPositiveUsedAmount = usedAmount === undefined || usedAmount > 0;
        var grantIsPresentInItems = grantItems.some(function (_a) {
            var ticketName = _a.ticketName, name = _a.name;
            return labelTicket === ticketName || labelTicket === name;
        });
        return grantHasPositiveUsedAmount || grantIsPresentInItems;
    };
}
