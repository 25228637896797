import { useActor } from '@xstate/react';
import { useCallback, useContext } from 'react';
import { useAppSelector } from 'redux/store';
import { getCrossSellingAlreadyTriggered } from 'redux/app';
import { WorkflowStateMachineContext } from './WorkflowStateMachineContext';

export const useWorkflowStateMachine = () => {
    const { workflowService } = useContext(WorkflowStateMachineContext);
    const [state, send] = useActor(workflowService);
    const crossSellingAlreadyTriggered = useAppSelector(getCrossSellingAlreadyTriggered);

    const goPrevious = useCallback(() => send({ type: 'PREVIOUS' }), [send]);
    const goNext = useCallback(
        () => send({ type: 'NEXT', crossSellingAlreadyTriggered }),
        [send, crossSellingAlreadyTriggered],
    );
    const reset = useCallback(() => send({ type: 'RESET' }), [send]);
    const goCurrentOrder = useCallback(
        (currentOrderId: string) => send({ type: 'CURRENT_ORDER', currentOrderId }),
        [send],
    );
    const goCart = useCallback(() => send({ type: 'CART' }), [send]);
    const triggerError = useCallback(() => send({ type: 'ERROR' }), [send]);

    return {
        state,
        goPrevious,
        goNext,
        reset,
        goCurrentOrder,
        goCart,
        triggerError,
    };
};
