import { ConsumptionModeType } from '@innovorder/order_detail';
import { useRedirectToHome } from 'hooks/useRedirectHome/useRedirectHome';
import { useWorkflowStateMachine } from 'hooks/useWorkflowStateMachine';
import {
    getCurrentConsumptionModeType,
    getIsFoodCourt,
    getRestaurantId,
    getTableId,
} from 'redux/app';
import { getBrand, getRestaurant } from 'redux/brand';
import { useAppSelector } from 'redux/store';

export const useWithHeaderVM = () => {
    const isFoodCourt = useAppSelector(getIsFoodCourt);
    const currentConsumptionModeType = useAppSelector(getCurrentConsumptionModeType);
    const tableId = useAppSelector(getTableId);
    const brand = useAppSelector(getBrand);
    const currentRestaurantId = useAppSelector(getRestaurantId);
    const currentRestaurant = useAppSelector(getRestaurant(currentRestaurantId));

    const tableNumber =
        currentConsumptionModeType === ConsumptionModeType.MODE_SIT_IN ? tableId : null;

    const { redirectToHome } = useRedirectToHome();
    const { goPrevious } = useWorkflowStateMachine();

    return {
        tableNumber,
        isFoodCourt,
        redirectToHome,
        goPrevious,
        restaurantLogo: currentRestaurant?.imageUrl,
        logoAriaLabel: isFoodCourt ? brand?.name : currentRestaurant?.name,
    };
};
