import React from 'react';
import {
    Button,
    RadioSelect,
    Title,
    PriceBreakDownSummary,
    HeaderTitle,
    WithHeader,
    Text,
} from 'components';
import { computePromoCodeDiscountFromOrderRecap } from 'pages/Cart/Cart.utils';
import { CartStepper } from 'components/CartStepper';
import { StyledIcon } from 'components/BackButton/BackButton.style';
import {
    ButtonContainer,
    Container,
    Picker,
    StyledBottomBar,
    StyledCallout,
    SummaryContainer,
} from './ChoosePayment.style';
import { useChoosePaymentVM } from './useChoosePaymentVM';
import ThreeDSErrorModal from './ThreeDSErrorModal';
import NoPaymentMethodModal from './NoPaymentMethodModal';

const ChoosePayment: React.FunctionComponent = () => {
    const {
        currency,
        totalPriceBreakdown,
        hasToPay,
        isLoading,
        buttonText,
        paymentMethodOptions,
        handlePaymentModeChange,
        paymentMode,
        handleSubmit,
        recap,
        threeDSError,
        isThreeDSErrorModalOpen,
        handleHideThreeDSErrorModal,
        isPaymentOptionsUnavailable,
        handleHideNoPaymentMethodModal,
    } = useChoosePaymentVM();

    return (
        <WithHeader isSticky={true}>
            <CartStepper />
            <Container>
                <HeaderTitle>
                    <Title text={'order_confirmation'} type={4} />
                </HeaderTitle>

                {hasToPay && (
                    <Picker>
                        <Title text={'payment.mode'} type={5} />
                        <RadioSelect
                            options={paymentMethodOptions}
                            selected={paymentMode || paymentMethodOptions[0]}
                            onChange={handlePaymentModeChange}
                        />
                        <NoPaymentMethodModal
                            isOpen={isPaymentOptionsUnavailable}
                            onHide={handleHideNoPaymentMethodModal}
                        />
                    </Picker>
                )}

                {currency && totalPriceBreakdown && (
                    <SummaryContainer>
                        <PriceBreakDownSummary
                            currency={currency}
                            discounts={computePromoCodeDiscountFromOrderRecap(recap!)}
                            {...totalPriceBreakdown}
                        />
                    </SummaryContainer>
                )}

                <StyledBottomBar>
                    {threeDSError && (
                        <StyledCallout>
                            <StyledIcon
                                icon="error-warning-line"
                                data-testid="error-warning-line"
                            />
                            <Text
                                text={'order_error_3ds.modal_description'}
                                type={3}
                                weight="Bold"
                            />
                        </StyledCallout>
                    )}
                    <ButtonContainer>
                        <Button
                            fullWidth
                            type="submit"
                            onClick={handleSubmit}
                            isLoading={isLoading}
                            disabled={isLoading}
                            size="l"
                        >
                            {hasToPay ? buttonText.pay : buttonText.confirm}
                        </Button>
                    </ButtonContainer>
                </StyledBottomBar>

                <ThreeDSErrorModal
                    isOpen={isThreeDSErrorModalOpen}
                    onHide={handleHideThreeDSErrorModal}
                />
            </Container>
        </WithHeader>
    );
};

export default ChoosePayment;
