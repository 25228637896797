import { useInterpret } from '@xstate/react';
import { routes } from 'core/routes';
import { useCustomNavigate } from 'hooks/useCustomNavigate';
import { ActionList } from 'hooks/useWorkflowStateMachine/types';
import { useLayoutEffect } from 'react';
import { useAppSelector } from 'redux/store';
import { useLocation } from 'react-router-dom';
import { getBrandOptionStatus, getIsOrderFollowUpEnabled } from 'redux/brand';
import { getRouteAttributesFromPath } from 'utils/routes';
import { getRestaurantId } from 'redux/app';
import { computeWorkflowStateMachine } from './useWorkflowStateMachine.utils';

export const useInitWorkflowStateMachine = () => {
    const { pathname } = useLocation();
    const isFoodCourt = useAppSelector(getBrandOptionStatus('FOOD_COURT_MODE'));
    const restaurantId = useAppSelector(getRestaurantId);
    const isOrderFollowUpEnabled = useAppSelector(getIsOrderFollowUpEnabled(restaurantId));

    const {
        navigateToHome,
        navigateToRestaurants,
        navigateToMenuHome,
        navigateToCart,
        navigateToCrossSelling,
        navigateToUserInformation,
        navigateToChoosePayment,
        navigateToOrderConfirmation,
        navigateToOrderFollowUp,
    } = useCustomNavigate();

    const actions: ActionList = {
        navigateToHome,
        navigateToRestaurants,
        navigateToMenuHome,
        navigateToCart,
        navigateToCrossSelling,
        navigateToUserInformation,
        navigateToChoosePayment,
        navigateToOrderConfirmation,
        navigateToOrderFollowUp,
    };

    const workflowService = useInterpret(
        computeWorkflowStateMachine({ isFoodCourt, isOrderFollowUpEnabled }),
        {
            actions,
        },
    );

    useLayoutEffect(() => {
        const matchedRoute = getRouteAttributesFromPath(pathname, routes);
        if (matchedRoute?.state) {
            workflowService.send({
                type: 'INIT',
                state: matchedRoute.state,
            });
        }
    }, [pathname, workflowService]);

    return workflowService;
};
