"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapOrderDetailDiscountToDiscount = void 0;
var discount_types_1 = require("../../../types/discount.types");
var big_numbers_1 = require("../../../utils/big-numbers");
var generate_scope_from_apply_method_1 = require("../../scopes/generate-scope-from-apply-method");
var mapOrderDetailDiscountToDiscount = function (discount) {
    var applyMethod = discount.strategy.applyMethod === discount_types_1.DiscountApplyMethod.RATE ? discount_types_1.APPLY_METHOD.RATE : discount_types_1.APPLY_METHOD.ABSOLUTE;
    if (!discount.strategy.scope) {
        if (discount_types_1.RemiseTypes.includes(discount.strategy.type) && discount.strategy.type !== discount_types_1.DISCOUNT_TYPE.LOYALTY) {
            discount.strategy.scope = {
                type: new Set([discount_types_1.DISCOUNTABLE_TYPE.PRODUCT]),
                filter: null,
            };
        }
        else {
            discount.strategy.scope = {
                type: (0, generate_scope_from_apply_method_1.generateScopeTypeFromApplyMethod)(discount.strategy.applyMethod),
                filter: null,
            };
        }
    }
    var strategy = discount_types_1.GrantStrategy.Always;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (discount.strategy.type === discount_types_1.DISCOUNT_TYPE.GRANT && discount.additionalData && discount.additionalData.strategy) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
        strategy = discount.additionalData.strategy;
    }
    return {
        label: discount.label ? discount.label : '',
        id: discount.id ? discount.id.toString() : '',
        type: discount.strategy.type,
        amount: discount.strategy.value,
        applyMethod: applyMethod,
        legacyApplyMethod: discount.strategy.applyMethod,
        strategy: strategy,
        scopeTypes: discount.strategy.scope.type,
        scopeFilterValue: discount.strategy.scope.filter ? discount.strategy.scope.filter.value : null,
        scopeFilterValueUncased: discount.strategy.scope.filter
            ? new Set(Array.from(discount.strategy.scope.filter.value).map(function (tag) { return tag.toString().toLocaleLowerCase(); }))
            : null,
        threshold: discount.threshold ? discount.threshold : 0,
        ceiling: discount.ceiling !== undefined ? discount.ceiling : null,
        position: discount.position !== undefined ? discount.position : null,
        discountables: [],
        taxRatesBreakdownNotRounded: [],
        taxRatesBreakdownRounded: [],
        usedAmountNotRoundedInclTax: big_numbers_1.big0,
        usedAmountNotRoundedExclTax: big_numbers_1.big0,
        usedAmountRoundedInclTax: 0,
        usedAmountRoundedExclTax: 0,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        additionalData: discount.additionalData,
        pricingRuleRawData: discount.pricingRuleRawData,
        grantRawData: discount.grantRawData,
    };
};
exports.mapOrderDetailDiscountToDiscount = mapOrderDetailDiscountToDiscount;
