import * as React from 'react';
import { Text } from 'components';
import { Container, AbsoluteBackButton, HeaderLogo, StyledChips } from './WithHeader.style';
import { useWithHeaderVM } from './useWithHeaderVM';

type WithHeaderProps = {
    isSticky?: boolean;
    disabledClickBack?: boolean;
    disabledClickLogo?: boolean;
    onClickBack?: () => void;
    onClickLogo?: () => void;
};

export const WithHeader: React.FunctionComponent<React.PropsWithChildren<WithHeaderProps>> = ({
    isSticky,
    disabledClickBack,
    disabledClickLogo,
    onClickBack,
    onClickLogo,
    children,
}) => {
    const { tableNumber, isFoodCourt, restaurantLogo, logoAriaLabel, goPrevious, redirectToHome } =
        useWithHeaderVM();

    const handleClickBack = () => {
        if (disabledClickBack) return;

        if (onClickBack) {
            onClickBack();
            return;
        }

        goPrevious();
    };

    const handleClickLogo = () => {
        if (disabledClickLogo) return;

        if (onClickLogo) {
            onClickLogo();
            return;
        }

        redirectToHome();
    };

    return (
        <>
            <Container isSticky={!!isSticky} data-testid="with-header">
                {!disabledClickBack && (
                    <AbsoluteBackButton onClick={handleClickBack} data-testid="back-button" />
                )}

                <HeaderLogo
                    role="img"
                    aria-label={logoAriaLabel}
                    onClick={handleClickLogo}
                    style={
                        isFoodCourt
                            ? {}
                            : {
                                  backgroundImage: `url(${restaurantLogo})`,
                              }
                    }
                    data-testid={isFoodCourt ? 'brand-logo' : 'restaurant-logo'}
                />

                {tableNumber !== null && (
                    <StyledChips>
                        <Text
                            data-testid="table-number"
                            text={`${tableNumber}`}
                            noTranslation
                            color="inherit"
                            type={3}
                            weight="Regular"
                        />
                    </StyledChips>
                )}
            </Container>
            {children}
        </>
    );
};
