"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.promocodeFromRegexpAdapter = exports.loyaltyFromOrderFlagsAdapter = exports.grantFromOrderFlagsAdapter = exports.grantFromOrderItemAdapter = exports.globalAbsoluteDiscountAdapter = exports.globalRateDiscountAdapter = exports.manualDiscountAdapter = void 0;
var bignumber_js_1 = require("bignumber.js");
var types_1 = require("../../../types");
var big_numbers_1 = require("../../../utils/big-numbers");
var types_2 = require("../types");
var utils_1 = require("./utils");
var manualDiscountAdapter = function (orderItem, children, digitsRange, index) {
    var discountApplyMethod = !new bignumber_js_1.BigNumber(orderItem.discount).isEqualTo(1) ||
        orderItem.gift ||
        new bignumber_js_1.BigNumber(orderItem.unitPriceWithTaxIncluded)
            .shiftedBy(digitsRange)
            .integerValue()
            .isEqualTo(new bignumber_js_1.BigNumber(orderItem.totalPriceWithTaxIncluded)
            .minus(new bignumber_js_1.BigNumber(orderItem.itemDiscount))
            .dividedBy(orderItem.quantity)
            .shiftedBy(digitsRange)
            .integerValue())
        ? types_1.DiscountApplyMethod.ABSOLUTE_CART
        : types_1.DiscountApplyMethod.RATE;
    var productIds = new Set(__spreadArray([
        orderItem.applyScopeId
    ], children.reduce(function (_productIds, child) {
        _productIds = __spreadArray(__spreadArray(__spreadArray([], _productIds, true), [child.applyScopeId], false), (0, utils_1.extractCustomizationsScopeIds)(child), true);
        return _productIds;
    }, []), true));
    var totalItemPriceWithCustomizations = (orderItem.customizations || []).reduce(function (_totalItemPriceWithCustomizations, customization, customizationIndex) {
        productIds.add((0, utils_1.generateCustomizationApplyScopeId)(customization, orderItem.index, customizationIndex));
        return _totalItemPriceWithCustomizations.plus(customization.customizationTotalPrice);
    }, new bignumber_js_1.BigNumber(orderItem.originalUnitPrice));
    var totalItemPrice = new bignumber_js_1.BigNumber(totalItemPriceWithCustomizations.gt(orderItem.originalUnitPrice)
        ? totalItemPriceWithCustomizations
        : orderItem.originalUnitPrice)
        .shiftedBy(digitsRange)
        .multipliedBy(orderItem.quantity);
    var itemDiscount = orderItem.gift ? totalItemPrice : new bignumber_js_1.BigNumber(orderItem.itemDiscount).shiftedBy(digitsRange);
    var discountValue = (discountApplyMethod === types_1.DiscountApplyMethod.ABSOLUTE_CART
        ? totalItemPrice.minus(totalItemPrice.minus(itemDiscount).dividedBy(orderItem.discount).integerValue())
        : new bignumber_js_1.BigNumber(orderItem.originalUnitPrice)
            .plus((orderItem.customizations || []).reduce(function (customizationUnitPriceSum, customization) {
            return new bignumber_js_1.BigNumber(customization.customizationUnitPrice)
                .multipliedBy(customization.customizationQuantity)
                .plus(customizationUnitPriceSum);
        }, big_numbers_1.big0))
            .minus(orderItem.unitPriceWithTaxIncluded)
            .multipliedBy(orderItem.quantity)
            .shiftedBy(digitsRange)).integerValue();
    return discountValue.gt(0)
        ? {
            id: "MANUAL_DISCOUNT_".concat(index),
            label: "MANUAL_DISCOUNT_".concat(index),
            strategy: {
                applyMethod: types_1.DiscountApplyMethod.ABSOLUTE_CART,
                type: types_1.DISCOUNT_TYPE.POS,
                value: discountValue.toNumber(),
                scope: {
                    type: new Set([types_1.DISCOUNTABLE_TYPE.PRODUCT]),
                    filter: { type: types_1.DiscountFilterType.MANUAL, value: productIds },
                },
            },
        }
        : null;
};
exports.manualDiscountAdapter = manualDiscountAdapter;
var globalRateDiscountAdapter = function (_a) {
    var discount = _a.discount, orderItems = _a.orderItems;
    var discountRate = new bignumber_js_1.BigNumber(discount).shiftedBy(2);
    var filter = (0, utils_1.getPosDiscountScopeFilter)(orderItems, discountRate);
    return discountRate.isZero() || !filter.value.size
        ? null
        : {
            id: 'POS_DISCOUNT',
            label: 'POS_DISCOUNT',
            strategy: {
                applyMethod: types_1.DiscountApplyMethod.RATE,
                type: types_1.DISCOUNT_TYPE.POS,
                value: discountRate.toNumber(),
                scope: {
                    type: new Set([types_1.DISCOUNTABLE_TYPE.PRODUCT]),
                    filter: filter,
                },
            },
        };
};
exports.globalRateDiscountAdapter = globalRateDiscountAdapter;
var globalAbsoluteDiscountAdapter = function (_a) {
    var discount = _a.discount, orderItems = _a.orderItems, totalPriceDiscountedWithTaxIncluded = _a.totalPriceDiscountedWithTaxIncluded, totalPriceWithTaxIncluded = _a.totalPriceWithTaxIncluded;
    var discountAbsolute = new bignumber_js_1.BigNumber(discount).shiftedBy(2);
    var filter = (0, utils_1.getPosAbsoluteDiscountScopeFilter)(orderItems);
    var discountIsEqualToDiscountedAmount = big_numbers_1.big0
        .plus(totalPriceWithTaxIncluded)
        .minus(totalPriceDiscountedWithTaxIncluded)
        .isEqualTo(discount);
    return discountAbsolute.isZero() || !filter.value.size || !discountIsEqualToDiscountedAmount
        ? null
        : {
            id: 'POS_DISCOUNT',
            label: 'POS_DISCOUNT',
            strategy: {
                applyMethod: types_1.DiscountApplyMethod.ABSOLUTE_CART,
                type: types_1.DISCOUNT_TYPE.POS,
                value: discountAbsolute.toNumber(),
                scope: {
                    type: new Set([types_1.DISCOUNTABLE_TYPE.PRODUCT]),
                    filter: filter,
                },
            },
        };
};
exports.globalAbsoluteDiscountAdapter = globalAbsoluteDiscountAdapter;
var grantFromOrderItemAdapter = function (orderItem, digitsRange) { return ({
    label: orderItem.name,
    id: null,
    strategy: {
        applyMethod: types_1.DiscountApplyMethod.ABSOLUTE_CART,
        type: types_1.DISCOUNT_TYPE.GRANT,
        value: new bignumber_js_1.BigNumber(orderItem.totalPriceWithTaxIncluded)
            .absoluteValue()
            .shiftedBy(digitsRange)
            .integerValue()
            .toNumber(),
        scope: {
            type: new Set([types_1.DISCOUNTABLE_TYPE.PRODUCT]),
            filter: null,
        },
    },
    additionalData: __assign(__assign({}, orderItem), { labelTicket: orderItem.name, strategy: 1 }),
}); };
exports.grantFromOrderItemAdapter = grantFromOrderItemAdapter;
var grantFromOrderFlagsAdapter = function (orderFlags, orderItem) {
    return orderFlags.reduce(function (_grant, orderFlag) {
        switch (orderFlag.name) {
            case types_2.PosOrderFlagName.GRANT_CODE: {
                _grant.label = orderFlag.value;
                break;
            }
            case types_2.PosOrderFlagName.GRANT_ID: {
                _grant.id = orderFlag.value;
                break;
            }
            case types_2.PosOrderFlagName.GRANT_AMOUNT: {
                _grant.strategy.value = parseInt(orderFlag.value, 10);
                break;
            }
            default:
                break;
        }
        return _grant;
    }, {
        label: '',
        id: null,
        strategy: {
            applyMethod: types_1.DiscountApplyMethod.ABSOLUTE_CART,
            type: types_1.DISCOUNT_TYPE.GRANT,
            value: 0,
            scope: {
                type: new Set([types_1.DISCOUNTABLE_TYPE.PRODUCT]),
                filter: null,
            },
        },
        additionalData: orderItem,
    });
};
exports.grantFromOrderFlagsAdapter = grantFromOrderFlagsAdapter;
var loyaltyFromOrderFlagsAdapter = function (orderFlags, orderItem) {
    return orderFlags.reduce(function (_loyalty, orderFlag) {
        switch (orderFlag.name) {
            case types_2.PosOrderFlagName.LOYALTY_CARD_ID: {
                _loyalty.label = orderFlag.value;
                break;
            }
            case types_2.PosOrderFlagName.LOYALTY_CARD_NUMBER: {
                _loyalty.id = orderFlag.value;
                break;
            }
            default:
                break;
        }
        return _loyalty;
    }, {
        label: '',
        id: null,
        strategy: {
            applyMethod: types_1.DiscountApplyMethod.ABSOLUTE_GLOBAL,
            type: types_1.DISCOUNT_TYPE.LOYALTY,
            value: new bignumber_js_1.BigNumber(orderItem.originalUnitPrice)
                .absoluteValue()
                .shiftedBy(2)
                .integerValue()
                .toNumber(),
            scope: {
                type: new Set([types_1.DISCOUNTABLE_TYPE.PRODUCT, types_1.DISCOUNTABLE_TYPE.FEE]),
                filter: null,
            },
        },
        additionalData: orderItem,
    });
};
exports.loyaltyFromOrderFlagsAdapter = loyaltyFromOrderFlagsAdapter;
var promocodeFromRegexpAdapter = function (label, orderItem) { return ({
    label: label,
    id: null,
    strategy: {
        applyMethod: types_1.DiscountApplyMethod.ABSOLUTE_CART,
        type: types_1.DISCOUNT_TYPE.PROMOCODE,
        value: new bignumber_js_1.BigNumber(orderItem.originalUnitPrice).absoluteValue().shiftedBy(2).integerValue().toNumber(),
        scope: { type: new Set([types_1.DISCOUNTABLE_TYPE.PRODUCT]), filter: null },
    },
    additionalData: orderItem,
}); };
exports.promocodeFromRegexpAdapter = promocodeFromRegexpAdapter;
