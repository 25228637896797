import { getRequiredAction } from 'redux/requiredAction';
import { useAppSelector } from 'redux/store';
import { isStripeIntentRequiredAction } from 'utils/stripe';
import { isPaygreenIntentRequiredAction } from 'utils/paygreen';
import { useState } from 'react';
import { getIsFoodCourt, getIsPaymentConfirmed } from 'redux/app';

export const useOrderVM = () => {
    const isFoodCourt = useAppSelector(getIsFoodCourt) ?? false;
    const requiredAction = useAppSelector(getRequiredAction);
    const isPaymentConfirmed = useAppSelector(getIsPaymentConfirmed);

    const [showEmailReceiptModal, setShowEmailReceiptModal] = useState<boolean>(false);

    const handleShowEmailReceiptModal = () => {
        setShowEmailReceiptModal(true);
    };

    const handleHideEmailReceiptModal = () => {
        setShowEmailReceiptModal(false);
    };

    const isStripe =
        requiredAction &&
        'intentId' in requiredAction &&
        'payment_intent_client_secret' in requiredAction;

    const isPaygreen =
        requiredAction && 'transactionId' in requiredAction && 'paymentUrl' in requiredAction;

    return {
        isPaymentConfirmed,
        hasStripeRequiredAction: isStripe ? isStripeIntentRequiredAction(requiredAction) : false,
        hasPaygreenRequiredAction: isPaygreen
            ? isPaygreenIntentRequiredAction(requiredAction)
            : false,
        paygreenPaymentUrl: isPaygreen ? requiredAction?.paymentUrl : undefined,
        isFoodCourt,
        showEmailReceiptModal,
        handleShowEmailReceiptModal,
        handleHideEmailReceiptModal,
    };
};
