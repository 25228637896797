"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapUrssafToProductRecap = void 0;
var types_1 = require("../../../types");
var mapUrssafToProductRecap = function (urssaf) { return ({
    label: urssaf.label,
    id: 'Compensatory-Product',
    orderItemType: types_1.DISCOUNTABLE_TYPE.COMPENSATORY_PRODUCT,
    taxRate: urssaf.taxRate,
    totalPrice: urssaf.totalPriceRounded,
    unitPrice: urssaf.totalPriceRounded,
    tags: [],
    depth: 0,
    quantity: 1,
    additionalData: {
        orderItemType: 'Compensation',
    },
}); };
exports.mapUrssafToProductRecap = mapUrssafToProductRecap;
