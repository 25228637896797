import { ConsumptionModeType, EnrichedProduct } from '@innovorder/order_detail';
import { PaymentMode } from 'pages/Payment/ChoosePayment/useChoosePaymentVM';
import { ConsumptionModeListState } from 'redux/consumptionModeList';
import { OrdersState } from 'redux/orders';
import { ConsumptionMode } from 'services/consumptionMode/consumptionMode.type';
import { MultiOrderToCreate } from 'services/multi-order/multi-order.type';
import { ChannelId, OrderMainStatus, OrderToCreate } from 'services/order/order.type';
import { computeCartPayload } from 'utils/cart';

export type GetPaymentMethodIdPayload = {
    paymentMode: PaymentMode;
    consumptionModeList?: ConsumptionMode[];
    currentConsumptionModeType: ConsumptionModeType | null;
};

export type PaygreenMetadata = { firstName: string; lastName: string; email: string };

export type ComputeCreateOrderPayload = {
    orderCart: EnrichedProduct[];
    restaurantId: number;
    currentMenuId: number;
    tableId: number | null;
    paymentMethodId?: number;
    totalInclTaxWithDiscount: number;
    currentConsumptionModeType: ConsumptionModeType;
    stripePaymentMethodId?: string;
    paygreenMetadata?: PaygreenMetadata;
    orderComment?: string;
    orderPromocode?: string;
    delay?: number;
    userName: string | null;
};

export type ComputeCreateMultiOrderPayload = {
    orders: OrdersState;
    consumptionModeList: ConsumptionModeListState;
    currentConsumptionModeType: ConsumptionModeType;
    brandId: number;
    tableId: number | null;
    delayList: Record<number, number>;
    paymentMode?: PaymentMode;
    paymentMethodId?: number;
    stripePaymentMethodId?: string;
    userName: string | null;
};

export const getPaymentMethodId = ({
    paymentMode,
    consumptionModeList,
    currentConsumptionModeType,
}: GetPaymentMethodIdPayload): number | undefined => {
    if (!consumptionModeList) return undefined;

    const apiConsumptionMode = consumptionModeList.find(
        ({ type }) => type === currentConsumptionModeType,
    );

    const apiPaymentMethod = apiConsumptionMode?.paymentMethods.find(
        ({ code }) => code.toLocaleLowerCase() === paymentMode.toLocaleLowerCase(),
    );

    return apiPaymentMethod?.paymentMethodId;
};

export const computeExpectedAt = (today: Date, delayInMinutes = 0): string =>
    new Date(today.setMinutes(today.getMinutes() + delayInMinutes)).toISOString();

export const generateMetadata = ({
    stripePaymentMethodId,
    paygreenPaymentDetails,
}: {
    stripePaymentMethodId?: string;
    paygreenPaymentDetails?: PaygreenMetadata;
}): string | undefined => {
    if (stripePaymentMethodId) return `{"stripePaymentMethodId":"${stripePaymentMethodId}"}`;
    if (paygreenPaymentDetails)
        return `{"paygreenPaymentType":"TRD","firstName":"${paygreenPaymentDetails.firstName}","lastName":"${paygreenPaymentDetails.lastName}","email":"${paygreenPaymentDetails.email}"}`;
    return undefined;
};

export const extractTableNameProperty = ({
    currentConsumptionModeType,
    tableId,
}: {
    currentConsumptionModeType: ConsumptionModeType;
    tableId: number | null;
}) => {
    if (currentConsumptionModeType === ConsumptionModeType.MODE_SIT_IN && tableId !== null)
        return { tableName: String(tableId) };

    return {};
};

export const computeCreateOrderPayload = ({
    orderCart,
    restaurantId,
    currentMenuId,
    tableId,
    paymentMethodId,
    totalInclTaxWithDiscount,
    currentConsumptionModeType,
    stripePaymentMethodId,
    paygreenMetadata,
    orderComment,
    orderPromocode,
    delay,
    userName,
}: ComputeCreateOrderPayload): OrderToCreate => {
    return {
        restaurantId,
        channelId: ChannelId.WEB,
        consumptionMode: currentConsumptionModeType,
        menuId: currentMenuId,
        comment: orderComment,
        promocode: orderPromocode,
        cart: computeCartPayload(orderCart),
        expectedAt: computeExpectedAt(new Date(), delay),
        ...extractTableNameProperty({ currentConsumptionModeType, tableId }),
        ...(userName !== null ? { userName } : {}),
        payments:
            !paymentMethodId || !totalInclTaxWithDiscount
                ? []
                : [
                      {
                          paymentMethodId,
                          amount: totalInclTaxWithDiscount,
                          quantity: 1,
                          currency: 'EUR',
                          metadata: generateMetadata({
                              stripePaymentMethodId,
                              paygreenPaymentDetails: paygreenMetadata,
                          }),
                      },
                  ],
    };
};

export const computeCreateMultiOrderPayload = ({
    orders,
    consumptionModeList,
    paymentMode,
    currentConsumptionModeType,
    stripePaymentMethodId,
    brandId,
    delayList,
    tableId,
    userName,
    idempotencyKey,
}: ComputeCreateMultiOrderPayload & { idempotencyKey: string }): MultiOrderToCreate => {
    const restaurantCarts = Object.values(orders!).map(({ restaurantId, menuId, cart, recap }) => ({
        restaurantId,
        menuId,
        cart: computeCartPayload(cart),
        expectedAt: computeExpectedAt(new Date(), delayList[restaurantId]),
        payments: !paymentMode
            ? []
            : [
                  {
                      paymentMethodId: getPaymentMethodId({
                          paymentMode,
                          consumptionModeList: consumptionModeList![restaurantId],
                          currentConsumptionModeType,
                      }) as number,
                      amount: recap.totalPrice.totalInclTaxWithDiscount,
                      quantity: 1,
                  },
              ],
    }));

    return {
        channelId: ChannelId.WEB,
        brandId,
        consumptionMode: currentConsumptionModeType,
        payment: {
            currency: 'EUR',
            metadata: `{"stripePaymentMethodId":"${stripePaymentMethodId}"}`,
        },
        idempotencyKey,
        restaurantCarts,
        ...extractTableNameProperty({ currentConsumptionModeType, tableId }),
        ...(userName !== null ? { userName } : {}),
    };
};

export const isMainStatusValid = (mainStatus: OrderMainStatus | null): boolean =>
    mainStatus !== null &&
    [
        OrderMainStatus.ORDER_PLACED,
        OrderMainStatus.ORDER_VALIDATED,
        OrderMainStatus.ORDER_PREPARING,
        OrderMainStatus.ORDER_PREPARED,
        OrderMainStatus.ORDER_PROCESSED,
    ].includes(mainStatus);
