import { Loader, PriceBreakDownSummary } from 'components';
import { PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { useStripeIntegrationForm } from 'hooks/useStripeIntegrationForm/useStripeIntegrationForm';
import { computePromoCodeDiscountFromOrderRecap } from 'pages/Cart/Cart.utils';
import BaseConfirmPayment from 'pages/Payment/ConfirmPayment/BaseConfirmPayment';
import { Container, LoaderContainer, SummaryContainer } from './GooglePayForm.style';

const GooglePayForm: React.FunctionComponent = () => {
    const { paymentRequest, isLoadingCreateOrder, currency, totalPriceBreakdown, recap } =
        useStripeIntegrationForm();

    return (
        <BaseConfirmPayment isLoading={isLoadingCreateOrder}>
            <Container>
                {isLoadingCreateOrder && (
                    <LoaderContainer>
                        <Loader size={50} />
                    </LoaderContainer>
                )}

                {paymentRequest && !isLoadingCreateOrder && (
                    <>
                        <SummaryContainer>
                            {currency && totalPriceBreakdown && (
                                <PriceBreakDownSummary
                                    currency={currency}
                                    discounts={computePromoCodeDiscountFromOrderRecap(recap!)}
                                    {...totalPriceBreakdown}
                                />
                            )}
                        </SummaryContainer>
                        <PaymentRequestButtonElement options={{ paymentRequest }} />
                    </>
                )}
            </Container>
        </BaseConfirmPayment>
    );
};

export default GooglePayForm;
