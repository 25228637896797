import { useCreateOrder } from 'hooks/useCreateOrder';
import { isMainStatusValid } from 'hooks/useCreateOrder/useCreateOrder.utils';
import { useCustomFlush } from 'hooks/useCustomFlush';
import { useCallback, useEffect } from 'react';
import { getIsFoodCourt, getIsPaymentConfirmed, getRestaurantId, updateAppState } from 'redux/app';
import { getRequiredAction } from 'redux/requiredAction';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { isFetchError } from 'services';
import {
    useConfirmMultiOrderMutation,
    useGetMultiOrderQuery,
} from 'services/multi-order/multi-order.endpoint';
import { MultiOrderInformations } from 'services/multi-order/multi-order.type';
import { useConfirmOrderMutation, useGetOrderQuery } from 'services/order/order.endpoints';
import { ChannelId, OrderInformations, OrderMainStatus } from 'services/order/order.type';

export type MonoOrMultiOrder =
    | {
          data?: MultiOrderInformations;
          isMultiOrder: true;
          isLoading: boolean;
      }
    | {
          data?: OrderInformations;
          isMultiOrder: false;
          isLoading: boolean;
      };

export const useGetOrders = (id?: string): MonoOrMultiOrder => {
    const dispatch = useAppDispatch();

    const isFoodCourt = useAppSelector(getIsFoodCourt);
    const restaurantId = useAppSelector(getRestaurantId);
    const isPaymentConfirmed = useAppSelector(getIsPaymentConfirmed);
    const requiredAction = useAppSelector(getRequiredAction);

    const { flushCart, flushRequiredAction, flushIsPaymentConfirmed } = useCustomFlush();
    const { resetIdempotencyKeyAndNavigateToOrderError } = useCreateOrder();

    const setCurrentOrderId = useCallback(
        (currentOrderId: string | null) => dispatch(updateAppState({ currentOrderId })),
        [dispatch],
    );

    /**
     * Solo Order Part
     */
    const {
        data: singleOrderData,
        isLoading: singleOrderLoading,
        refetch: refetchSingleOrder,
    } = useGetOrderQuery(
        {
            orderUuid: id || '',
            restaurantId: restaurantId || 0,
            channelId: ChannelId.WEB,
        },
        { skip: !id || !restaurantId || !!isFoodCourt || isFoodCourt === null },
    );

    const [
        confirmSingleOrder,
        { isSuccess: singleOrderConfirmSuccess, error: singleOrderConfirmError },
    ] = useConfirmOrderMutation();

    useEffect(() => {
        if (
            isPaymentConfirmed &&
            requiredAction &&
            singleOrderData &&
            isMainStatusValid(singleOrderData.mainStatus)
        ) {
            flushCart();
            flushRequiredAction();
            flushIsPaymentConfirmed();
        }
    }, [
        singleOrderData,
        isPaymentConfirmed,
        requiredAction,
        flushCart,
        flushRequiredAction,
        flushIsPaymentConfirmed,
    ]);

    useEffect(() => {
        if (
            isPaymentConfirmed &&
            requiredAction &&
            singleOrderData &&
            [
                OrderMainStatus.ORDER_COULD_NOT_BE_PROCESSED,
                OrderMainStatus.ORDER_REFUSED,
                OrderMainStatus.ORDER_CANCELLED,
            ].includes(singleOrderData?.mainStatus)
        ) {
            setCurrentOrderId(null);
            resetIdempotencyKeyAndNavigateToOrderError();
        }
    }, [
        singleOrderData,
        isPaymentConfirmed,
        requiredAction,
        setCurrentOrderId,
        resetIdempotencyKeyAndNavigateToOrderError,
    ]);

    useEffect(() => {
        if (
            restaurantId &&
            isPaymentConfirmed &&
            requiredAction &&
            'intentId' in requiredAction &&
            'orderId' in requiredAction &&
            singleOrderData?.mainStatus === OrderMainStatus.ORDER_AWAITING_ACTION
        ) {
            confirmSingleOrder({
                orderId: requiredAction.orderId,
                orderUuid: requiredAction.orderUuid,
                restaurantId,
                channelId: ChannelId.WEB,
                paymentIntentId: requiredAction.intentId,
            });
        }
    }, [restaurantId, singleOrderData, isPaymentConfirmed, requiredAction, confirmSingleOrder]);

    useEffect(() => {
        if (singleOrderConfirmSuccess) {
            refetchSingleOrder();
        }
    }, [singleOrderConfirmSuccess, refetchSingleOrder]);

    useEffect(() => {
        if (singleOrderConfirmError && !isFetchError(singleOrderConfirmError)) {
            setCurrentOrderId(null);
            resetIdempotencyKeyAndNavigateToOrderError();
        }
    }, [singleOrderConfirmError, setCurrentOrderId, resetIdempotencyKeyAndNavigateToOrderError]);

    /**
     * Multi Order Part
     */
    const {
        data: multiOrderData,
        isLoading: multiOrderLoading,
        refetch: refetchMultiOrder,
    } = useGetMultiOrderQuery(
        {
            multiOrderId: id || '',
            channelId: ChannelId.WEB,
        },
        { skip: !id || !isFoodCourt || isFoodCourt === null },
    );

    const [
        confirmMultiOrder,
        { isSuccess: multiOrderConfirmSuccess, error: multiOrderConfirmError },
    ] = useConfirmMultiOrderMutation();

    useEffect(() => {
        if (isPaymentConfirmed && requiredAction && multiOrderData?.status === 'VALIDATED') {
            flushCart();
            flushRequiredAction();
            flushIsPaymentConfirmed();
        }
    }, [
        multiOrderData,
        isPaymentConfirmed,
        requiredAction,
        flushCart,
        flushRequiredAction,
        flushIsPaymentConfirmed,
    ]);

    useEffect(() => {
        if (isPaymentConfirmed && requiredAction && multiOrderData?.status === 'ERROR') {
            setCurrentOrderId(null);
            resetIdempotencyKeyAndNavigateToOrderError();
        }
    }, [
        multiOrderData,
        isPaymentConfirmed,
        requiredAction,
        setCurrentOrderId,
        resetIdempotencyKeyAndNavigateToOrderError,
    ]);

    useEffect(() => {
        if (
            isPaymentConfirmed &&
            requiredAction &&
            'multiOrderId' in requiredAction &&
            multiOrderData?.status === 'AWAITING_USER_ACTION'
        ) {
            confirmMultiOrder({
                channelId: ChannelId.WEB,
                multiOrderId: requiredAction.multiOrderId,
                paymentIntentId: requiredAction.intentId,
                restaurantCarts: requiredAction.restaurantCarts,
            });
        }
    }, [multiOrderData, isPaymentConfirmed, requiredAction, confirmMultiOrder]);

    useEffect(() => {
        if (multiOrderConfirmSuccess) {
            refetchMultiOrder();
        }
    }, [multiOrderConfirmSuccess, refetchMultiOrder]);

    useEffect(() => {
        if (multiOrderConfirmError && !isFetchError(multiOrderConfirmError)) {
            setCurrentOrderId(null);
            resetIdempotencyKeyAndNavigateToOrderError();
        }
    }, [multiOrderConfirmError, setCurrentOrderId, resetIdempotencyKeyAndNavigateToOrderError]);

    if (isFoodCourt)
        return {
            data: requiredAction ? undefined : multiOrderData,
            isMultiOrder: true,
            isLoading: !!requiredAction || multiOrderLoading,
        };

    return {
        data: requiredAction ? undefined : singleOrderData,
        isMultiOrder: false,
        isLoading: !!requiredAction || singleOrderLoading,
    };
};
