"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPriceBreakdownNotRounded = exports.getPriceBreakdown = void 0;
var bignumber_js_1 = require("bignumber.js");
var big_numbers_1 = require("./big-numbers");
var getPriceBreakdown = function (amount, taxRate) {
    var inclTax = amount.integerValue(bignumber_js_1.BigNumber.ROUND_HALF_UP);
    var exclTax = inclTax
        .dividedBy(big_numbers_1.big1.plus(new bignumber_js_1.BigNumber(taxRate).dividedBy(big_numbers_1.big10000)))
        .integerValue(bignumber_js_1.BigNumber.ROUND_HALF_UP);
    var tax = inclTax.minus(exclTax);
    return [inclTax.toNumber(), exclTax.toNumber(), tax.toNumber()];
};
exports.getPriceBreakdown = getPriceBreakdown;
var getPriceBreakdownNotRounded = function (amount, taxRate) {
    var inclTax = amount;
    var exclTax = inclTax.dividedBy(big_numbers_1.big1.plus(new bignumber_js_1.BigNumber(taxRate).dividedBy(big_numbers_1.big10000)));
    return [inclTax, exclTax];
};
exports.getPriceBreakdownNotRounded = getPriceBreakdownNotRounded;
