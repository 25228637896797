import React from 'react';
import {
    Button,
    RadioSelect,
    Title,
    PriceBreakDownSummary,
    HeaderTitle,
    WithHeader,
} from 'components';
import { computePromoCodeDiscountFromOrderRecap } from 'pages/Cart/Cart.utils';
import { StyledBottomBar, Container, Picker, SummaryContainer } from '../ChoosePayment.style';
import { useChoosePaymentVM } from '../useChoosePaymentVM';

const PaymentLaterChoice: React.FunctionComponent = () => {
    const {
        navigateBackToChoosePayment,
        currency,
        buttonText,
        totalPriceBreakdown,
        isLoading,
        paymentMethodLaterOptions,
        isPaymentMethodLaterOptions,
        handlePaymentModeChange,
        paymentMode,
        handleSubmit,
        recap,
    } = useChoosePaymentVM();

    return (
        <WithHeader
            isSticky={true}
            onClickBack={navigateBackToChoosePayment}
            disabledClickBack={isLoading}
            disabledClickLogo={isLoading}
        >
            <Container>
                <HeaderTitle>
                    <Title text={'order_confirmation'} type={4} />
                </HeaderTitle>

                <Picker>
                    <Title text={'payment.payment_later'} type={5} />
                    <RadioSelect
                        options={paymentMethodLaterOptions}
                        selected={paymentMode || paymentMethodLaterOptions[0]}
                        onChange={handlePaymentModeChange}
                    />
                </Picker>

                {currency && totalPriceBreakdown && (
                    <SummaryContainer>
                        <PriceBreakDownSummary
                            currency={currency}
                            discounts={computePromoCodeDiscountFromOrderRecap(recap!)}
                            {...totalPriceBreakdown}
                        />
                    </SummaryContainer>
                )}

                <StyledBottomBar withPadding>
                    <Button
                        fullWidth
                        type="submit"
                        onClick={handleSubmit}
                        isLoading={isLoading}
                        disabled={isLoading || !isPaymentMethodLaterOptions}
                        size="l"
                    >
                        {buttonText.confirm}
                    </Button>
                </StyledBottomBar>
            </Container>
        </WithHeader>
    );
};

export default PaymentLaterChoice;
