import { SelectOptions } from 'components/RadioSelect';
import { TranslationKey } from 'locales/fr';
import { SVGIcon, SVGIcons } from 'components/SvgIcon/SVGIcon';
import { PaymentMode } from './useChoosePaymentVM';

export const computeInitialPaymentMethod = ({
    googlePayError,
    applePayError,
}: {
    googlePayError: boolean;
    applePayError: boolean;
}): PaymentMode => {
    if (navigator.userAgent.match('Android') && !googlePayError) {
        return 'google_pay';
    }

    if (navigator.userAgent.match('iPhone') && !applePayError) {
        return 'apple_pay';
    }

    return 'stripe';
};

export const generatePaymentMethodOptions = ({
    hasStripe,
    googlePayError,
    applePayError,
    hasEdenredPaymentMethod,
    edenredEligibilityConditions,
    useCashPayment,
    useCreditCardPayment,
    hasPaygreenPaymentMethod,
    paygreenEligibilityConditions,
}: {
    hasStripe: boolean;
    googlePayError?: boolean;
    applePayError?: boolean;
    hasEdenredPaymentMethod?: boolean;
    edenredEligibilityConditions?: {
        cartContainsOnlyEligibleProducts?: boolean;
        amountIsBelowMaximumDaily?: boolean;
    };
    usePaygreenPayments?: boolean;
    useCashPayment?: boolean;
    useCreditCardPayment?: boolean;
    hasPaygreenPaymentMethod?: boolean;
    paygreenEligibilityConditions?: {
        cartContainsOnlyEligibleProducts?: boolean;
    };
}) => {
    const stripeOptions: SelectOptions<PaymentMode>[] = [];
    const luncheonOptions: SelectOptions<PaymentMode>[] = [];
    const payLaterOptions: SelectOptions<PaymentMode>[] = [];

    if (hasStripe) {
        if (navigator.userAgent.match('Android')) {
            stripeOptions.push({
                name: googlePayError ? 'order_error_stripe' : 'payment.google_pay',
                icon: () => <SVGIcon icon={SVGIcons.CARRET} />,
                value: 'google_pay',
                disabled: !!googlePayError,
            });
        }

        if (navigator.userAgent.match('iPhone')) {
            stripeOptions.push({
                name: applePayError ? 'order_error_stripe' : 'payment.apple_pay',
                icon: () => <SVGIcon icon={SVGIcons.APPLEPAY} />,
                value: 'apple_pay',
                disabled: !!applePayError,
            });
        }

        stripeOptions.push({
            name: 'payment.bank_card',
            icon: () => <SVGIcon icon={SVGIcons.BANKCARD} />,
            value: 'stripe',
        });
    }

    if (hasEdenredPaymentMethod && edenredEligibilityConditions) {
        let error: TranslationKey | undefined;

        if (!edenredEligibilityConditions.amountIsBelowMaximumDaily) {
            error = 'payment.luncheon_voucher_errors.maximum_daily_amount';
        } else if (!edenredEligibilityConditions.cartContainsOnlyEligibleProducts) {
            error = 'payment.luncheon_voucher_errors.cart_with_non_eligible_products';
        }

        luncheonOptions.push({
            name: 'payment.edenred',
            hasLineBreakBeforeDescription: true,
            icon: () => <SVGIcon icon={SVGIcons.EDENRED} />,
            value: 'edenred',
            disabled: !!error,
            error,
        });
    }

    if (hasPaygreenPaymentMethod && paygreenEligibilityConditions) {
        let error: TranslationKey | undefined;

        if (!paygreenEligibilityConditions.cartContainsOnlyEligibleProducts) {
            error = 'payment.luncheon_voucher_errors.cart_with_non_eligible_products';
        }

        luncheonOptions.push({
            name: 'payment.paygreen.label',
            description: 'payment.paygreen.description',
            hasLineBreakBeforeDescription: true,
            icon: () => <SVGIcon icon={SVGIcons.PAYGREEN} />,
            value: 'paygreen',
            disabled: !!error,
            error,
        });
    }

    // Allows to display a "link" to luncheon vouchers list if at least one is usable
    if (luncheonOptions.length >= 1) {
        stripeOptions.push({
            name: 'payment.choice.luncheon_voucher',
            isStep: true,
            value: 'luncheon_vouchers',
        });
    }

    if (useCashPayment) {
        payLaterOptions.push({
            name: 'payment.cash',
            icon: () => <SVGIcon icon={SVGIcons.CASHLINE} />,
            value: 'cash',
        });
    }

    if (useCreditCardPayment) {
        payLaterOptions.push({
            name: 'payment.bank_card',
            icon: () => <SVGIcon icon={SVGIcons.BANKCARD} />,
            value: 'creditcard',
        });
    }

    // Allows to display a "link" to payment later list if at least one is usable
    if (payLaterOptions.length >= 1) {
        stripeOptions.push({
            name: 'payment.payment_later',
            isStep: true,
            value: 'payment_later',
        });
    }

    return { stripeOptions, luncheonOptions, payLaterOptions };
};
