import { Text } from 'components/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { computePrice } from 'utils/price';
import { Discount, PriceBreakdown } from 'services/order/order.type';
import { Loader } from 'components/Loader';
import {
    BreakDownRow,
    PriceBreakDownSummaryContainer,
    PriceBreakDownSummaryTitle,
    TotalRow,
} from './PriceBreakDownSummary.style';

export type PriceBreakDownSummaryProps = PriceBreakdown & {
    discounts?: Discount[];
    currency: string;
    isLoading?: boolean;
};

export const PriceBreakDownSummary: React.FunctionComponent<PriceBreakDownSummaryProps> = ({
    totalExclTax,
    totalInclTaxWithDiscount,
    totalTax,
    currency,
    discounts,
    isLoading = false,
}) => {
    const { i18n } = useTranslation();
    return (
        <PriceBreakDownSummaryContainer>
            <PriceBreakDownSummaryTitle text={'price_break_down.title'} type={5} />

            <BreakDownRow>
                <Text text={'price_break_down.subtotal'} type={3} color="secondaryTextColor" />
                {!isLoading && (
                    <Text
                        text={computePrice(i18n, totalExclTax, currency)}
                        noTranslation
                        type={3}
                        color="secondaryTextColor"
                    />
                )}
                {isLoading && <Loader size={16} />}
            </BreakDownRow>

            <BreakDownRow>
                <Text text={'price_break_down.taxes'} type={3} color="secondaryTextColor" />
                {!isLoading && (
                    <Text
                        text={computePrice(i18n, totalTax, currency)}
                        noTranslation
                        type={3}
                        color="secondaryTextColor"
                    />
                )}
                {isLoading && <Loader size={16} />}
            </BreakDownRow>

            {discounts &&
                discounts.map(({ value, name }) => (
                    <BreakDownRow key={name}>
                        <Text
                            text={'price_break_down.discount'}
                            values={{ name }}
                            data-testid="promocode"
                            type={2}
                        />
                        {!isLoading && (
                            <Text
                                text={computePrice(i18n, -value, currency)}
                                noTranslation
                                type={2}
                            />
                        )}
                        {isLoading && <Loader size={16} />}
                    </BreakDownRow>
                ))}

            <TotalRow>
                <Text text={'price_break_down.total'} weight="Bold" type={2} />
                {!isLoading && (
                    <Text
                        text={computePrice(i18n, totalInclTaxWithDiscount, currency)}
                        noTranslation
                        weight="Bold"
                        type={2}
                    />
                )}
                {isLoading && <Loader size={16} />}
            </TotalRow>
        </PriceBreakDownSummaryContainer>
    );
};
