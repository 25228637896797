import { useMemo } from 'react';
import { getCurrentConsumptionModeType, getRestaurantId } from 'redux/app';
import { useAppSelector } from 'redux/store';
import { getConsumptionModeList } from 'redux/consumptionModeList';
import { getPaymentMethodId, useCreateOrder } from 'hooks/useCreateOrder';
import { PaymentMode } from 'pages/Payment/ChoosePayment/useChoosePaymentVM';
import { PaygreenMetadata } from '../useCreateOrder/useCreateOrder.utils';

export const useConfirmPayment = (paymentMode: PaymentMode) => {
    const restaurantId = useAppSelector(getRestaurantId);
    const currentConsumptionModeType = useAppSelector(getCurrentConsumptionModeType);
    const consumptionModeList = useAppSelector(getConsumptionModeList);

    const { createOrder, isLoading } = useCreateOrder();

    const paymentMethodId: number | undefined = useMemo(
        () =>
            getPaymentMethodId({
                paymentMode,
                consumptionModeList: consumptionModeList?.[restaurantId!],
                currentConsumptionModeType,
            }),
        [paymentMode, currentConsumptionModeType, consumptionModeList, restaurantId],
    );

    const handlePaymentSubmit = async ({
        stripePaymentMethodId,
        edenredAccessToken,
        paygreenMetadata,
    }: {
        stripePaymentMethodId?: string;
        edenredAccessToken?: string;
        paygreenMetadata?: PaygreenMetadata;
    } = {}) => {
        await createOrder({
            paymentMode,
            stripePaymentMethodId,
            paymentMethodId,
            edenredAccessToken,
            paygreenMetadata,
        });
    };

    return { handlePaymentSubmit, isLoadingCreateOrder: isLoading };
};
