import { Title, Button, TextArea, Input, WithHeader, HeaderTitle, BottomBar } from 'components';
import { PriceBreakDownSummary } from 'components/PriceBreakDownSummary';
import React from 'react';
import { CartItemDisplayer } from 'components/CartItemDisplayer';
import ProductErrorModal from 'components/ProductErrorModal';
import { CartStepper } from 'components/CartStepper';
import {
    CartContainer,
    CartContentContainer,
    ProductSummaryContainer,
    PriceBreakDownSummaryContainer,
} from './Cart.style';
import EmptyCart from './EmptyCart';
import { useCartVM } from './useCartVM';
import CartModal from './CartModal';
import ConsumptionPicker from './ConsumptionPicker';

const Cart: React.FunctionComponent = () => {
    const {
        isEmpty,
        goBack,
        goNext,
        isLoading,
        handleUpdateProductQuantityInCart,
        debouncingQuantity,
        ordersTotalPriceBreakdown,
        comment,
        handleCommentChange,
        isCommentModalOpen,
        hideCommentModal,
        showCommentModal,
        submitCommentModal,
        promocode,
        isPromocodeModalOpen,
        handlePromocodeChange,
        hidePromocodeModal,
        showPromocodeModal,
        submitPromocodeModal,
        promocodeError,
        discounts,
        currency,
        isFoodCourt,
        buttonText,
        restaurantOrders,
    } = useCartVM();

    if (isEmpty) {
        return <EmptyCart navigate={goBack} />;
    }

    return (
        <WithHeader isSticky={true} onClickBack={goBack}>
            <CartStepper />
            <ConsumptionPicker />
            <CartContainer>
                <HeaderTitle>
                    <Title text={'cart.title'} type={4} isUnderlined={true} />
                </HeaderTitle>

                <CartContentContainer>
                    <ProductSummaryContainer>
                        <CartItemDisplayer
                            isFoodCourt={isFoodCourt}
                            isLoading={isLoading}
                            currency={currency}
                            onValueChange={handleUpdateProductQuantityInCart}
                            restaurantOrders={restaurantOrders}
                            debouncingQuantity={debouncingQuantity}
                        />
                    </ProductSummaryContainer>
                    <div>
                        {!isFoodCourt && (
                            <>
                                <CartModal
                                    key="discount"
                                    title={'cart.discount.title'}
                                    headerTitle={'cart.discount.add'}
                                    buttonTitle={'cart.discount.validate'}
                                    isOpen={isPromocodeModalOpen}
                                    isLoading={isLoading}
                                    onShow={showPromocodeModal}
                                    onHide={hidePromocodeModal}
                                    onSubmit={submitPromocodeModal}
                                    value={promocode}
                                    isValid={!!discounts.length}
                                >
                                    <Input
                                        onChange={handlePromocodeChange}
                                        autoFocus
                                        value={promocode}
                                        error={promocodeError}
                                    />
                                </CartModal>
                                <CartModal
                                    key="comment"
                                    title={'comment'}
                                    headerTitle={'add_comment'}
                                    buttonTitle={'validate'}
                                    isOpen={isCommentModalOpen}
                                    isLoading={isLoading}
                                    onShow={showCommentModal}
                                    onHide={hideCommentModal}
                                    onSubmit={submitCommentModal}
                                    value={comment}
                                >
                                    <TextArea
                                        rows={5}
                                        maxCount={150}
                                        defaultValue={comment}
                                        onChange={handleCommentChange}
                                        autoFocus
                                    />
                                </CartModal>
                            </>
                        )}
                    </div>
                    <PriceBreakDownSummaryContainer>
                        <PriceBreakDownSummary
                            {...ordersTotalPriceBreakdown}
                            discounts={discounts}
                            currency="EUR"
                            isLoading={isLoading}
                        />
                    </PriceBreakDownSummaryContainer>
                </CartContentContainer>
                <BottomBar withPadding>
                    <Button fullWidth onClick={goNext} size="l">
                        {buttonText}
                    </Button>
                </BottomBar>
                <ProductErrorModal />
            </CartContainer>
        </WithHeader>
    );
};

export default Cart;
