import { SvyButtonProps, SvyIcon } from '@innovorder/savory';
import { useWorkflowStateMachine } from 'hooks/useWorkflowStateMachine';
import { useEffect, useCallback, useState, ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    getCurrentConsumptionModeType,
    getRestaurantId,
    getTableId,
    updateAppState,
} from 'redux/app';
import { getBrand, getBrandOptionStatus, getRestaurant } from 'redux/brand';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { useSearchParams } from 'react-router-dom';
import { ConsumptionModeType } from '@innovorder/order_detail';
import { HANDLED_CONSUMPTION_MODES, useGetConsumptionModes } from 'hooks/useGetConsumptionModes';
import { getConsumptionModeList } from 'redux/consumptionModeList';
import { resetOrders } from 'redux/orders';
import { SVGIcon, SVGIcons } from 'components/SvgIcon/SVGIcon';
import { parseRestaurantId } from './Home.utils';

export const HomeSteps = ['initial', 'consumption_modes'] as const;
export type HomeStep = typeof HomeSteps[number];
export type HomeButton = {
    name: string;
    onClick: () => void;
    elementRight?: ReactElement;
    elementLeft?: ReactElement;
    variant?: SvyButtonProps['variant'];
};

export const useHomeVM = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [searchParams] = useSearchParams();
    const paramsRestaurantId = parseRestaurantId(searchParams.get('restaurantId') ?? undefined);
    const stateRestaurantId = useAppSelector(getRestaurantId);
    const restaurantId = paramsRestaurantId || stateRestaurantId || null;
    const restaurant = useAppSelector(getRestaurant(restaurantId || null));
    const tableId = useAppSelector(getTableId);

    const restaurantIds = useMemo(() => (restaurantId ? [restaurantId] : []), [restaurantId]);
    useGetConsumptionModes(restaurantIds);
    const currentConsumptionMode = useAppSelector(getCurrentConsumptionModeType);
    const consumptionModeList = useAppSelector(getConsumptionModeList);

    const isFoodCourt = useAppSelector(getBrandOptionStatus('FOOD_COURT_MODE'));
    const brand = useAppSelector(getBrand);
    const { goNext } = useWorkflowStateMachine();

    const [step, setStep] = useState<HomeStep>('initial');

    const displayedName = isFoodCourt ? brand?.name : restaurant?.name;
    const isQrCodeError = !isFoodCourt && !restaurantId;
    const isAlcoolBannerActivated = brand?.module_ordering_flows[0].isAlcoolBannerActivated;

    const [isConsumptionModeError, setIsConsumptionModeError] = useState(false);
    const [consumptionModeSelected, setConsumptionModeSelected] = useState(false);

    const handleSelectConsumptionMode = useCallback(
        (consumptionMode: ConsumptionModeType) => {
            if (currentConsumptionMode !== consumptionMode) {
                dispatch(resetOrders(undefined));
            }
            dispatch(updateAppState({ currentConsumptionModeType: consumptionMode }));
            setConsumptionModeSelected(true);
        },
        [dispatch, currentConsumptionMode],
    );

    const shouldDisplayContentForStep: Record<HomeStep, boolean> = {
        initial: true,
        consumption_modes: false,
    };

    const buttons: Record<HomeStep, HomeButton[]> = {
        initial: [
            {
                name: t(`home_page.${step}.cta1`),
                onClick: () => setStep('consumption_modes'),
                elementRight: <SvyIcon icon="arrow-right-s-line" />,
                variant: undefined,
            },
        ],
        consumption_modes: [
            {
                name: tableId
                    ? t(`home_page.${step}.cta1`) + t(`home_page.table_number`, { tableId })
                    : t(`home_page.${step}.cta1`),
                onClick: () => handleSelectConsumptionMode(ConsumptionModeType.MODE_SIT_IN),
                elementLeft: <SVGIcon icon={SVGIcons.SIT_IN} />,
            },
            {
                name: t(`home_page.${step}.cta2`),
                onClick: () => handleSelectConsumptionMode(ConsumptionModeType.MODE_TAKE_AWAY),
                elementLeft: <SVGIcon icon={SVGIcons.TAKE_AWAY} />,
            },
        ],
    };

    useEffect(() => {
        if (restaurantId) dispatch(updateAppState({ restaurantId }));
    }, [dispatch, restaurantId]);

    useEffect(() => {
        if (consumptionModeSelected && currentConsumptionMode) {
            setConsumptionModeSelected(false);
            goNext();
        }
    }, [consumptionModeSelected, currentConsumptionMode, goNext]);

    useEffect(() => {
        if (isFoodCourt) return;
        if (!restaurantId) return;

        const restaurantConsumptionModes = consumptionModeList?.[restaurantId];

        if (restaurantId && restaurantConsumptionModes) {
            const handledConsumptionModes = restaurantConsumptionModes.filter((consumptionMode) =>
                HANDLED_CONSUMPTION_MODES.includes(consumptionMode.type),
            );

            if (handledConsumptionModes.length === 1) {
                handleSelectConsumptionMode(handledConsumptionModes[0].type);
            }
            if (handledConsumptionModes.length === 0) {
                setIsConsumptionModeError(true);
            }
        }
    }, [
        dispatch,
        handleSelectConsumptionMode,
        goNext,
        isFoodCourt,
        consumptionModeList,
        restaurantId,
    ]);

    return {
        step,
        buttons: buttons[step],
        shouldDisplayContent: shouldDisplayContentForStep[step],
        displayedName,
        isQrCodeError,
        isConsumptionModeError,
        isAlcoolBannerActivated,
    };
};
