"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Discount = void 0;
var big_numbers_1 = require("../../utils/big-numbers");
var tax_computer_1 = require("../../utils/tax-computer");
var discount_scope_1 = require("./discount-scope");
var Discount = /** @class */ (function () {
    function Discount(data, discountables) {
        this.data = data;
        this.appliedAmount = big_numbers_1.big0;
        this.discountableDiscountedAmounts = new Map();
        this.type = data.strategy.type;
        this.applyMethod = data.strategy.applyMethod;
        this.scope = new discount_scope_1.DiscountScope(data.strategy.scope);
        this.filteredDiscountables = this.scope.apply(discountables);
    }
    Object.defineProperty(Discount.prototype, "taxRatesBreakdown", {
        get: function () {
            var _this = this;
            return Array.from(this.discountables
                .reduce(function (taxesSums, _a) {
                var taxRate = _a.taxRate, id = _a.id;
                var discountAmount = _this.discountableDiscountedAmounts.get(id) || big_numbers_1.big0;
                if (taxesSums.has(taxRate)) {
                    taxesSums.set(taxRate, taxesSums.get(taxRate).plus(discountAmount));
                }
                else {
                    taxesSums.set(taxRate, discountAmount);
                }
                return taxesSums;
            }, new Map())
                .entries()).reduce(function (_taxRateBreakdowns, _a) {
                var taxRate = _a[0], amount = _a[1];
                var _b = (0, tax_computer_1.getPriceBreakdown)(amount, taxRate), totalInclTax = _b[0], totalExclTax = _b[1], totalTax = _b[2];
                _taxRateBreakdowns.push({
                    taxRate: taxRate,
                    totalPrice: {
                        totalInclTax: totalInclTax,
                        totalExclTax: totalExclTax,
                        totalTax: totalTax,
                        totalInclTaxWithDiscount: totalInclTax,
                        totalExclTaxWithDiscount: totalExclTax,
                        totalTaxWithDiscount: totalTax,
                    },
                });
                return _taxRateBreakdowns;
            }, []);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Discount.prototype, "recap", {
        get: function () {
            var _this = this;
            return {
                label: this.data.label,
                id: "".concat(this.data.id),
                amount: this.discountables
                    .reduce(function (amount, _a) {
                    var id = _a.id;
                    return amount.plus(_this.discountableDiscountedAmounts.get(id) || big_numbers_1.big0);
                }, big_numbers_1.big0)
                    .toNumber(),
                strategy: __assign({}, this.data.strategy),
                taxRatesBreakdown: this.taxRatesBreakdown,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                additionalData: this.data.additionalData,
                grantRawData: this.data.grantRawData,
                pricingRuleRawData: this.data.pricingRuleRawData,
            };
        },
        enumerable: false,
        configurable: true
    });
    return Discount;
}());
exports.Discount = Discount;
